<template>
    <div>
        <!--the below el row is for website-->
        <el-row style="display: flex; align-items: center;background-color: #17484C ;">
            <el-col :span="22" v-if="!isMobile()">
                <div v-if="getUserLoggedIn" class="component-title">
                    Welcome, <strong>{{$store.state.studentFullName}}</strong>
                </div>
                <div v-else class="component-title">
                    <img :src="`${publicPath}img/sv_homepage.svg`" class="yooni-logo" width="125px"
                 style="height: 100%; padding: 0.5vh 0.5vw;">
                </div>
            </el-col>
            <el-col :span="22" v-else>
                &nbsp;
            </el-col>
            <el-col :span="2" v-if="!isMobile()">
                <profile-avatar></profile-avatar>
            </el-col>
        </el-row>
        <el-row :gutter="24" v-if="!isMobile()" :style="isMobile()?{height: '80vh'}:{height: '90vh'}"
                style="overflow-y: auto; overflow-x: hidden; padding: 16px 24px;background-color: #377F7E;">
            <el-col :span="16">
                <el-row>
                    <CourseFilterDesktopSearch :filters="{}"/>
                </el-row>
                <!--                <el-row style="margin-top: 1.5vh;" v-if="!getIsPremiumUser">-->
                <!--                    <el-col>-->
                <!--                        <web-first-block @showPayment="showPaymentDialog"/>-->
                <!--                    </el-col>-->
                <!--                </el-row>-->
                <el-row style="margin-top: 1.5vh;">
                    <el-col>
                        <web-second-block @showPayment="showPaymentDialog"></web-second-block>
                    </el-col>
                </el-row>
                <el-row :gutter="20" style="margin-top: 1.5vh;">
                    <el-col :span="12">
                        <web-small-blocks :status="true" :title="'Course Search'"></web-small-blocks>
                    </el-col>
                    <el-col :span="12">
                        <web-small-blocks :status="false" :title="'Points Predict'"></web-small-blocks>
                    </el-col>
                </el-row>
                <el-row :gutter="20" style="margin-top: 1.5vh;">
                    <el-col :span="12">
                        <web-small-blocks :status="false" :title="'CAO Form Explore'"></web-small-blocks>
                    </el-col>
                    <el-col :span="12">
                        <web-small-blocks :status="false" :title="'Are you a Guidance Counsellor?'"
                                          @showGuidancePopup="showPrebook=true"></web-small-blocks>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="8">
                <div class="display-subject-div">
                    <el-row>
                        <el-col :span="20">
                            <div
                                    class="caoPrediction-home caoPrediction"
                                    v-if="!getUserLoggedIn || !$store.state.predictedCAOPoints">
                                Predict your CAO points
                            </div>
                            <div class="caoPrediction-home caoPrediction"
                                 v-else>
                                Your predicted CAO points <span
                                    style="font-weight: 600; font-family: Niramit, serif !important;">
                                {{$store.state.predictedCAOPoints}}</span>
                            </div>
                        </el-col>
                        <el-col :span="4">
                            <i @click="$router.push('/points_predict/')"
                               class="el-icon-edit"
                               style="cursor: pointer;
                                    color: #ffffff;
                                    font-weight: 600;
                                    font-size: 1.5vw;
                                    padding: 0.5vw;"
                               v-if="getUserLoggedIn"></i>
                            <!--                            <button-->
                            <!--                                    class="edit-subject-button"-->
                            <!--                                    @click="$router.push('/points_predict/')" v-if=getUserLoggedIn>-->
                            <!--                                Edit-->
                            <!--                            </button>-->
                        </el-col>
                    </el-row>
                    <br>
                    <el-row :gutter="5"
                            class="caoPrediction-home">
                        <el-col :span="12">
                            <div class="header-1">
                                Subject
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="header-1">
                                Level
                            </div>
                        </el-col>
                        <el-col :span="4">
                            <div class="header-1">
                                Grade
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="5" :key="key" style="margin-bottom: 1vh;"
                            v-for="(subjectItem, key) in selectedSubjectsList">
                        <el-col :span="12">
                            <div :class="{'blur-subject': !getUserLoggedIn || subjectItem.subject_name === 'Subject Title'}"
                                 class="home-subject-display">
                                {{subjectItem.subject_name}}
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div :class="{'blur-subject': !getUserLoggedIn || subjectItem.subject_name === 'Subject Title'}"
                                 class="home-subject-display">
                                <div class="home-subject-display" v-if="subjectItem.level !== ''">
                                    {{subjectItem.level}}
                                </div>
                                <div class="home-subject-display" v-else>
                                    -
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="4">
                            <div :class="{'blur-subject': !getUserLoggedIn || subjectItem.subject_name === 'Subject Title'}"
                                 class="home-subject-display">
                                {{getGradeLabel(subjectItem.grade)}}
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <div style="background: white; margin-top: 16px;">
                    <spot-light></spot-light>
                </div>
            </el-col>
        </el-row>
        <!--Below el-row is for mobile-->
        <el-row style="height: 78vh; overflow-y: auto; overflow-x: hidden" v-else>
            <el-col>
                <el-row>
                    <CourseFilterDesktopSearch :filters="{}"/>
                </el-row>
                <!--                <el-row style="padding: 0 16px;" :style="{'margin-top': '10px'}" v-if="!getIsPremiumUser">-->
                <!--                    <el-col>-->
                <!--                        <mobile-first-block @showPayment="showPaymentDialog"></mobile-first-block>-->
                <!--                    </el-col>-->
                <!--                </el-row>-->
                <el-row style="padding: 0 16px;" :class="{'disabled': !getUserLoggedIn && !getIsPremiumUser}"
                        :style="{'margin-top': '10px'}">
                    <el-col>
                        <mobile-second-block @showPayment="showPaymentDialog"></mobile-second-block>
                    </el-col>
                </el-row>
                <el-row style="padding: 0 16px;" :gutter="20" :style="{'margin-top': '10px'}">
                    <el-col :span="12">
                        <el-card @click.native="$router.push('/course/search/')">
                            <div class="small-block-heading">
                                Course Search
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="12">
                        <el-card :class="{'disabled': !getUserLoggedIn}"
                                 @click.native="getUserLoggedIn?$router.push('/points_predict/'):false">
                            <div :style="!getUserLoggedIn ? {'color': 'rgba(0, 0, 0, 0.3)'}: {'color': 'rgba(0, 0, 0)'}"
                                 class="small-block-heading">
                                Points Predict
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
                <el-row style="padding: 0 16px;" :gutter="20" :style="{'margin-top': '10px'}">
                    <el-col :span="12">
                        <el-card :class="{'disabled': !getUserLoggedIn && !getIsPremiumUser}"
                                 @click.native="getUserLoggedIn?$router.push('/cao_form/'):false">
                            <div :style="!getUserLoggedIn && !getIsPremiumUser ? {'color': 'rgba(0, 0, 0, 0.3)'}: {'color': 'rgba(0, 0, 0)'}"
                                 class="small-block-heading">
                                CAO Form Explore
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="12">
                        <!--                        <el-card :class="{'disabled': !getUserLoggedIn && !getIsPremiumUser }">-->
                        <!--                            <div class="pre-table">-->
                        <!--                                <div class="small-block-heading"-->
                        <!--                                     @click="showPrebook=true"-->
                        <!--                                     :style="!getUserLoggedIn && !getIsPremiumUser ? {'color': 'rgba(0, 0, 0, 0.3)'}: {'color': 'rgba(0, 0, 0)'}">-->
                        <!--                                    Get Guidance-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                        </el-card>-->
                        <el-card>
                            <div :style="{'color': 'rgba(0, 0, 0)'}"
                                 @click="showPrebook=true"
                                 class="small-block-heading">
                                Are you Counsellor?
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
                <el-row style="padding: 16px;">
                    <el-col>
                        <div class="display-subject-div">
                            <el-row>
                                <el-col :span="20">
                                    <div class="caoPrediction-home caoPrediction"
                                         v-if="!getUserLoggedIn || !$store.state.predictedCAOPoints">
                                        Predict your CAO points
                                    </div>
                                    <div class="caoPrediction-home caoPrediction"
                                         v-else>
                                        Your predicted CAO points <strong>{{$store.state.predictedCAOPoints}}</strong>
                                    </div>
                                </el-col>
                                <el-col :offset="3" :span="1">
                                    <!--                                    <el-button class="edit-subject-button" type="success"-->
                                    <!--                                               @click="$router.push('/points_predict/')" v-if=getUserLoggedIn-->
                                    <!--                                               icon="el-icon-edit"></el-button>-->
                                    <i @click="$router.push('/points_predict/')"
                                       class="el-icon-edit"
                                       style="
                                        color: #ffffff;
                                        font-weight: 600;
                                        font-size: calc(1.5vh + 1.5vw);
                                        padding: calc(0.5vw + 0.5vh);" v-if="getUserLoggedIn"></i>
                                    <!--                                    <button-->
                                    <!--                                            class="edit-subject-button"-->
                                    <!--                                            @click="$router.push('/points_predict/')" v-if=getUserLoggedIn>-->
                                    <!--                                        Edit-->
                                    <!--                                    </button>-->
                                </el-col>
                            </el-row>
                            <el-row
                                    class="caoPrediction-home">
                                <el-col :span="12">
                                    <div class="header-1">
                                        Subject
                                    </div>
                                </el-col>
                                <el-col :span="8">
                                    <div class="header-1">
                                        Level
                                    </div>
                                </el-col>
                                <el-col :span="4">
                                    <div class="header-1">
                                        Grade
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row :key="key" style="margin-bottom: 1vh;"
                                    v-for="(subjectItem, key) in selectedSubjectsList">
                                <el-col :span="12">
                                    <div :class="{'blur-subject': !getUserLoggedIn || subjectItem.subject_name === 'Subject Title'}"
                                         class="home-subject-display">
                                        {{subjectItem.subject_name}}
                                    </div>
                                </el-col>
                                <el-col :span="8">
                                    <div :class="{'blur-subject': !getUserLoggedIn || subjectItem.subject_name === 'Subject Title'}"
                                         class="home-subject-display">
                                        {{subjectItem.level}}
                                    </div>
                                </el-col>
                                <el-col :span="4">
                                    <div :class="{'blur-subject': !getUserLoggedIn || subjectItem.subject_name === 'Subject Title'}"
                                         class="home-subject-display">
                                        {{subjectItem.grade}}
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                        <!--                        <div style="padding: 16px; background: white; margin-top: 16px; border-radius: 10px;">-->
                        <!--                            <el-row class="mobile-block1">-->
                        <!--                                <div class="mobile-heading1">-->
                        <!--                                    Get Guidance-->
                        <!--                                </div>-->
                        <!--                            </el-row>-->
                        <!--                            <el-row :gutter="20">-->
                        <!--                                <el-col class="">-->
                        <!--                                    <div class="mobile-block1 mobile-description1">-->
                        <!--                                        Video call our Career Guidance Professionals.-->
                        <!--                                        Pre-register for your slot now by entering your name and email.-->
                        <!--                                        We will let you know when a time becomes available.-->
                        <!--                                    </div>-->
                        <!--                                </el-col>-->
                        <!--                            </el-row>-->
                        <!--                            <el-row :gutter="20">-->
                        <!--                                <el-col>-->
                        <!--                                    <div style="padding: 5px;">-->
                        <!--                                        <pre-book></pre-book>-->
                        <!--                                    </div>-->
                        <!--                                </el-col>-->
                        <!--                            </el-row>-->
                        <!--                        </div>-->
                    </el-col>
                </el-row>
                <el-row style="padding: 0 16px 16px;">
                    <div style="background: white;">
                        <spot-light></spot-light>
                    </div>
                </el-row>
            </el-col>
        </el-row>
        <!--        <personal-information :status="noSchoolCollegeInfo" @close="closeDialogue"-->
        <!--                              v-if="noSchoolCollegeInfo && !getIsAdmin"></personal-information>-->
        <payment-popover :status="paymentDialog" @close="closePaymentDialog" v-if="paymentDialog"></payment-popover>
        <register-guidance-counsellor :show-dialog="showPrebook" @close="showPrebook = false" v-if="showPrebook">
        </register-guidance-counsellor>
    </div>
</template>

<script>
  import WebSecondBlock from '../../page_components/HomepageComponents/web/WebSecondBlock';
  import WebSmallBlocks from '../../page_components/HomepageComponents/web/WebSmallBlocks';
  import MobileSecondBlock from '../../page_components/HomepageComponents/mobile/MobileSecondBlock';
  import ProfileAvatar from '../../main_components/ProfileAvatar';
  import PaymentPopover from '../../main_components/PaymentPopover';
  import RegisterGuidanceCounsellor from '../../main_components/RegisterGuidanceCounsellor';
  import CourseFilterDesktopSearch from '../../page_components/CourseFilterComponents/web/CourseFilterDesktopSearch';
  import SpotLight from '../../page_components/PartnersComponents/SpotLight';

  export default {
    name: 'HomePage',
    components: {
      SpotLight,
      CourseFilterDesktopSearch,
      RegisterGuidanceCounsellor,
      PaymentPopover,
      ProfileAvatar,
      MobileSecondBlock, WebSmallBlocks, WebSecondBlock,
    },
    beforeMount() {
      let self = this;
      // self.$store.state.loadingScreen = true;
      // self.checkUserLoggedIn().then(response => {
      //   if (response.user_type === 2) {
      //     self.$router.push('/counsellor/home/');
      //   }
      //   if (self.$store.state.isAdmin) {
      //     self.$router.push('/admin/');
      //   }
      //   self.$store.state.loadingScreen = false;
      // }).catch(error => {
      //   self.$store.state.loadingScreen = false;
      // });
      if (self.$store.state.userType.toString() === '2') {
        self.$router.push('/counsellor/home/');
      } else if (self.$store.state.isAdmin) {
        self.$router.push('/admin/');
      }
    },
    computed: {},
    mounted() {
      this.sendAnalytics();
      this.fetchSubjects();
      if (this.$store.state.userLoggedIn) {
        this.getPredictedCAOPoints().then(response => {
          response;
        }).catch(error => {
          error;
        });
      }
    },
    methods: {
      getGradeLabel: function(grade) {
        // this.$ga.event('getGradeLabel', 'load', 'getGradeLabel');
        return this.gradeMapping[grade];
      },
      showPaymentDialog: function() {
        this.paymentDialog = true;
      },
      closePaymentDialog: function() {
        this.paymentDialog = false;
      },
    },
    data: () => ({
      showPrebook: false,
      paymentDialog: false,
      loadingScreen: false,
      userLoggedIn: false,
      selectedSubjectsList: [],
      gradeMapping: {
        'O1': 'O1',
        'O2': 'O2',
        'O3': 'O3',
        'O4': 'O4',
        'O5': 'O5',
        'O6': 'O6',
        'O7': 'O7',
        'O8': 'O8',
        'H1': 'H1',
        'H2': 'H2',
        'H3': 'H3',
        'H4': 'H4',
        'H5': 'H5',
        'H6': 'H6',
        'H7': 'H7',
        'H8': 'H8',
        '1': 'Distinction',
        '2': 'Merit',
        '3': 'Pass',
        'F1': 'F1',
        'F2': 'F2',
      },
    }),
  };
</script>

<style scoped>
    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }

    .clearfix:after {
        clear: both
    }

    .box-card {
        /*height: 100%;*/
        /*width: 100%;*/
    }

    .small-block-heading {
        font-family: Niramit, serif !important;
        font-size: calc(1.5vh + 1.5vw);
        text-align: center;
        color: #000000;
        height: calc(80px + 1.5vh + 1.5vw);
        display: flex;
        align-items: center;
        padding: 0 20px;
        justify-content: center;
    }

    .pre-table {
        display: table;
        width: 100%;
    }

    .disabled {
        cursor: not-allowed;
        /*background: #DBDBDB;*/
        /* #C4C4C4 - Mid Grey 3 */

        box-sizing: border-box;
        border-radius: 5px;
        color: #FFFFFF;
    }
</style>
<style>
    .el-card__body {
        padding: 5px !important;
    }
</style>
