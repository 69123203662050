<template>
    <!--    <el-card-->
    <!--            @click.native="getIsPremiumUser ? $router.push('skillsvista_fit/'):$router.push({path: '/payment/', query: { redirect: $route.fullPath }})"-->
    <!--            class="box-card">-->
    <el-card
            @click.native="getIsPremiumUser ? $router.push('skillsvista_fit/'):$router.push({path: '/login/', query: { redirect: '/skillsvista_fit/' }})"
            class="box-card">
        <el-row style="display: flex; align-items: center;">
            <div style="width: 35%">
                <img :src="`${publicPath}img/homepage/second-block.svg`" alt="no image" class="image-box"/>
            </div>
            <div style="width: 65%;">
                <div class="mobile-block1">
                    <span class="mobile-heading1">
                        Your Skillsvista Fit!
                    </span>
                    <div style="position: absolute; right: 1.5vw; top: 1.5vw;" v-if="!getIsPremiumUser">
                        <lock-image-png></lock-image-png>
                    </div>
                </div>
                <div class="mobile-block1">
                    <p class="mobile-description1">
                        We match you with your best-fit college courses through scientific analysis of your Academics,
                        Personality and Interests.
                    </p>
                </div>
            </div>
        </el-row>
    </el-card>
</template>

<script>
  import LockImagePng from '../../CommonComponents/LockImagePng';

  export default {
    name: 'MobileSecondBlock',
    mounted() {
    },
    data: function() {
      return {};
    },
    components: {LockImagePng},
  };
</script>

<style scoped>
    .image-box {
        max-width: 100%;
        max-height: 100%;
        padding: 5px;
    }

    img {
        margin: auto;
    }

    .box-card {
        background: #ffffff;
        border: 1px solid #DDDDDD;
        box-sizing: border-box;
        border-radius: 4px;
    }
</style>
