<template>
    <div :class="{mainScreen: !isMobile()}"
         :style="isMobile()?{height: '70vh'}:{}"
         style="padding: 16px; overflow-y: auto;">
        <el-row class="row-head">
            <el-col :span="12" class="yooni-fit-head-mobile-3 head-mobile-3">
                Your Career fit!
            </el-col>
        </el-row>
        <el-row style="height: auto">
            <div class="career-fit-desc">
                To take the Career Fit quiz, mark your interest in each activity shown. You will be matched with your
                top 5 career sectors out of over 30, based on your personal interests and preferences.
                <br><br>
                Do not worry about whether you have the skills or training to do an activity, or how much money you
                might make. Simply think about whether you would enjoy doing it or not.
            </div>
        </el-row>
        <el-row style="" v-if="!isMobile()">
            <data-tables :data="getTableData" ref="data_table_web" class="career-questions-class">
                <el-table-column label="" prop="question" width="500"></el-table-column>
                <el-table-column label="Strongly Disagree" prop="strongly_disagree" width="auto">
                    <template slot-scope="scope">
                        <el-radio @change="checkAllOptionSelected()" label="strongly_disagree"
                                  v-model="scope.row.score">
                            <span></span></el-radio>
                    </template>
                </el-table-column>
                <el-table-column label="Disagree" prop="disagree" width="auto">
                    <template slot-scope="scope">
                        <el-radio @change="checkAllOptionSelected()" label="disagree" v-model="scope.row.score">
                            <span></span></el-radio>
                    </template>
                </el-table-column>
                <el-table-column label="Neutral" prop="neutral" width="auto">
                    <template slot-scope="scope">
                        <el-radio @change="checkAllOptionSelected()" label="neutral" v-model="scope.row.score">
                            <span></span></el-radio>
                    </template>
                </el-table-column>
                <el-table-column label="Agree" prop="agree" width="auto">
                    <template slot-scope="scope">
                        <el-radio @change="checkAllOptionSelected()" label="agree" v-model="scope.row.score">
                            <span></span>
                        </el-radio>
                    </template>
                </el-table-column>
                <el-table-column label="Strongly Agree" prop="strongly_agree" width="auto">
                    <template slot-scope="scope">
                        <el-radio @change="checkAllOptionSelected()" label="strongly_agree" v-model="scope.row.score">
                            <span></span></el-radio>
                    </template>
                </el-table-column>
            </data-tables>
        </el-row>
        <el-row style=" height: auto" v-else>
            <data-tables :data="getTableData" ref="data_table_web" class="career-questions-class">
                <el-table-column prop="question">
                    <template slot-scope="scope">
                        <div style="text-align: center !important; padding: 1vh 1vw 3vh; word-break: break-word !important;">
                            {{scope.row.question}}
                        </div>
                        <el-row>
                            <el-radio @change="checkAllOptionSelected()" class="mobile-radio"
                                      label="strongly_disagree"
                                      v-model="scope.row.score">
                                <span></span></el-radio>
                            <el-radio @change="checkAllOptionSelected()" class="mobile-radio" label="disagree"
                                      v-model="scope.row.score">
                                <span></span></el-radio>
                            <el-radio @change="checkAllOptionSelected()" class="mobile-radio" label="neutral"
                                      v-model="scope.row.score">
                                <span></span></el-radio>
                            <el-radio @change="checkAllOptionSelected()" class="mobile-radio" label="agree"
                                      v-model="scope.row.score">
                                <span></span>
                            </el-radio>
                            <el-radio @change="checkAllOptionSelected()" class="mobile-radio" label="strongly_agree"
                                      v-model="scope.row.score">
                                <span></span>
                            </el-radio>
                        </el-row>
                        <el-row>
                            <div class="mobile-radio radio-label">
                                <span>DISLIKE</span>
                            </div>
                            <div class="mobile-radio radio-label">
                                <span></span>
                            </div>
                            <div class="mobile-radio radio-label">
                                <span>NEUTRAL</span>
                            </div>
                            <div class="mobile-radio radio-label">
                                <span></span>
                            </div>
                            <div class="mobile-radio radio-label">
                                <span>LIKE</span>
                            </div>
                        </el-row>
                    </template>
                </el-table-column>
            </data-tables>

        </el-row>
        <el-row>
            <el-col :lg="{span: 11,offset: 7}" :md="{span: 11,offset: 7}"
                    :sm="{span: 24}" :xl="{span: 11,offset: 7}" :xs="{span: 24}">
                <div :key="pageIndex"
                     :style="pageIndex === activePage+1?{'width': getWidth, 'background-color': '#818FCB !important'}:{'width': getWidth, 'background-color': '#c6c8ce !important'}"
                     class="custom-pagination"
                     style=""
                     v-for="pageIndex in getPageSize"></div>
            </el-col>

        </el-row>
        <el-row style="margin-top: 1vh;">
            <el-col :lg="{span: 3,offset: 7}" :md="{span: 5,offset: 2}"
                    :sm="{span: 5,offset: 3}" :xl="{span: 3,offset: 7}" :xs="{span: 5,offset: 3}">
                <el-button :disabled="currentIndex < 1" @click="changeTableData('previous')"
                           class="previousQuestions careerTestNavButtons">
                    Back
                </el-button>

            </el-col>
            <el-col :lg="{span: 3,offset:1}" :md="{span: 5,offset:3}"
                    :sm="{span: 5,offset:2}" :xl="{span: 3,offset:1}" :xs="{span: 5,offset:2}">
                <el-button :disabled="!getAllAnswersGiven" @click="submitCareerTest()"
                           class="submitQuestions careerTestNavButtons">Submit
                </el-button>
            </el-col>
            <el-col :lg="{span: 3,offset:1}" :md="{span: 5,offset:2}"
                    :sm="{span: 5,offset:2}" :xl="{span: 3,offset:1}" :xs="{span: 5,offset:2}">
                <el-button :disabled="currentIndex + 10 > careerTestQuestions.length || !getEnableNext"
                           :title="!getEnableNext ? 'Please answer all questions' : 'Next'"
                           @click="changeTableData('next')"
                           class="nextQuestions careerTestNavButtons">
                    Next
                </el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
  import yooniFitMixins from '../mixins/yooni_fit_mixins';
  import $ from 'jquery';

  export default {
    mixins: [yooniFitMixins],
    data: function() {
      return {
        loadingScreen: false,
        currentIndex: 0,
        endIndex: 10,
        scores: ['strongly_disagree', 'disagree', 'neutral', 'agree', 'strongly_agree'],
        checkboxGroup1: ['Shanghai'],
        allAnswersGiven: false,
        currentPageNumber: 1,
        startPageNumber: 0,
        endPageNumber: 0,
        radio1: 'New York',
        careerTestQuestions: [],
        loading: false,
        columns: ['strongly_disagree, disagree, neutral, agree, strongly_agree'],
        page: {
          currentPage: 1, // current page
          pageSize: 10, // number of entries per page
          totalCount: 0, // total number of entries
        },
        sliderMarks: {
          25: 'Disagree',
          50: 'Neutral',
          75: 'Agree',
        },
        isPage: true,
        dataSourceId: '',
        activePage: 0,
        pageSize: 0,
        enableNext: false,
      };
    },
    computed: {
      getEnableNext: function() {
        return this.enableNext;
      },
      getWidth: function() {
        return (100 / this.pageSize) + '%';
      },
      getPageSize: function() {
        this.pageSize = Math.round(this.careerTestQuestions.length / 10) + 1;
        return this.pageSize;

      },
      getAllAnswersGiven: function() {
        return this.allAnswersGiven;
      },
      getTableData: function() {
        let self = this;
        this.careerTestQuestions = this.$store.state.careerTestQuestions;
        return this.careerTestQuestions.filter(function(value, index) {
          if (index >= self.currentIndex && index < self.endIndex) {
            return value;
          }
        });
      },
    },
    mounted() {
      $('.btn-prev').css('display', 'none');
      $('.btn-next').css('display', 'none');
      $('.pagination-bar').css('display', 'none');
      // $('.el-pagination').html('<el-button class="nextQuestions">Next</el-button>')
      // this.currentPageData = this.careerTestQuestions.splice(0, 10);
    },
    methods: {
      changeTableData: function(action) {
        this.enableNext = false;
        if (action === 'next') {
          if ((this.currentIndex + 10) <= this.careerTestQuestions.length) {
            this.currentIndex = this.currentIndex + 10;
            this.endIndex = this.currentIndex + 10;
            this.activePage = this.activePage + 1;
            this.checkAllOptionSelected();
            // this.enableNext = false;
          }
        } else {
          this.currentIndex = this.currentIndex - 10;
          this.endIndex = this.currentIndex + 10;
          this.activePage = this.activePage - 1;
          this.checkAllOptionSelected();
          // this.enableNext = true;
        }
      },
      checkAllOptionSelected: function() {
        let self = this;
        self.$nextTick(function() {
          let allCurrentAnswersSelected = self.$refs.data_table_web.$refs.elTable.data.filter(
              function(element) {
                if (element.score === '')
                  return element;
              });
          if (allCurrentAnswersSelected.length === 0) {
            self.enableNext = true;
          } else {
            self.enableNext = false;
          }
          let filteredData = self.careerTestQuestions.filter(function(value) {
            return value.score === '';
          });
          self.allAnswersGiven = filteredData.length === 0;
        });
      },
    },
    name: 'CareerTest',
  };
</script>
<style>


    @media only screen and (max-height: 1200px) {
        .mainScreen {
            height: 90vh;
        }
    }

    @media only screen and (max-height: 1024px) {
        .mainScreen {
            height: 83vh;
        }
    }

    @media only screen and (max-height: 900px) {
        .mainScreen {
            height: 79vh;
        }
    }

    @media only screen and (max-height: 750px) {
        .mainScreen {
            height: 76vh;
        }
    }

    @media only screen and (max-height: 600px) {
        .mainScreen {
            height: 70vh;
        }
    }

    .el-table .cell {
        word-break: break-word !important;
    }

    .career-questions-class .el-table tr td:first-child {
        text-align: justify !important;
    }

    .career-questions-class .el-table th {
        text-align: center !important;
    }

    .career-questions-class .el-table td {
        text-align: center !important;
    }

    .career-questions-class .el-table th > .cell {
        word-break: break-word !important;
        white-space: pre-wrap;
    }

    .career-questions-class .el-table__row:nth-child(even) {
        background-color: #efedff;
        color: #000;
        border-top: 1px solid lightgrey;
    }

    .career-questions-class .el-table__row:nth-child(odd) {
        background-color: #eaf5ff;
        color: #000;
        border-top: 1px solid lightgrey;
    }

    .career-questions-class .el-table th {
        background-color: #818FCB !important;
        color: #ffffff;
        font-weight: 600;
    }

</style>
<style scoped>
    .mobile-radio {
        width: 20%;
        margin: 0 auto 1vh;
        float: left;
        text-align: center;
        color: black;
    }

    .question-class th:first-child, .question-class td:first-child {
        text-align: left;
    }

    .question-class th, .question-class td {
        padding: 1vh 1vw;
        text-align: center;
    }

    .question-class tr:nth-child(odd) {
        background-color: #eaf5ff;
        color: #000;
        border-top: 1px solid lightgrey;
    }

    .question-class tr:nth-child(even) {
        background-color: #efedff;
        color: #000;
        border-top: 1px solid lightgrey;
    }

    .custom-pagination {
        margin: 10px 0 0;
        text-align: center;
        height: 10px;
        display: inline-block;
        border: 1px solid white;
    }
</style>
