import $ from 'jquery';
import {mapActions} from 'vuex';
import {store} from '../../store';

let yooniFitMixins = {

  beforeMount() {
  },
  updated() {
  },
  computed: {
    getPredictionsSubmitted: function() {
      return store.state.predictionsSubmitted;
    },
    getCareerFitCompleted: function() {
      return store.state.careerFitCompleted;
    },
    getInterestFitCompleted: function() {
      return store.state.interestFitCompleted;
    },
    getPointsPredicted: function() {
      return store.state.predictedCAOPoints !== false;
    },
  },
  data: function() {
    return {
      careerFitCompleted: false,
      interestFitCompleted: false,
      pointsPredicted: false,
      currentDate: new Date(),
      sliderValuesReversed: {
        0: 'strongly_disagree',
        25: 'disagree',
        50: 'neutral',
        75: 'agree',
        100: 'strongly_agree',
      },
      sliderValues: {
        'strongly_disagree': 0,
        'disagree': 25,
        'neutral': 50,
        'agree': 75,
        'strongly_agree': 100,
      },
      yooniFitOptions: [
        {
          'image_url': 'points_predict',
          'title': 'Points Predict',
          'desc': 'Take this chance to double check your subjects and potential grades are correct before continuing on to the next steps.',
          'button_text': 'Check Points',
          'type': 'points_predict',
        }, {
          'image_url': 'career_fit',
          'title': 'Career Fit',
          'desc': 'Take our career fit quiz and we’ll use your result to select courses geared towards the careers you’re likely to excel in.',
          'button_text': 'Take Quiz',
          'type': 'career_fit',
        }, {
          'image_url': 'personality_fit',
          'title': 'Personality Fit',
          'desc': 'Take our interest fit quiz to help us get to know you better and select course that suit your interests.',
          'button_text': 'Take Quiz',
          'type': 'personality_fit',
        }],
    };
  },
  methods: {
    ...mapActions([
      'setCareerTestQuestions',
      'setPersonalityTestQuestions',
    ]),
    submitCareerTest: function() {
      let self = this;
      let url = self.getBaseUrl() + '/recommendations/score_test_1/';
      const type = 'POST';
      self.$store.state.loadingScreen = true;
      let data = JSON.parse(JSON.stringify(self.careerTestQuestions));
      $.ajax({
        beforeSend: function(request) {
          request.setRequestHeader('Authorization',
              'Token ' + self.getAuthToken());
        },
        url,
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify(data),
        type: type,
        success: function(response) {
          response;
          self.activeName = 'course_fit';
          self.$store.careerFitCompleted = true;
          self.$store.state.loadingScreen = false;
          self.$emit('testCompleted');
          // self.$router.push({name: 'YooniFit'});
        },
        error: function(errorData) {
          console.log(errorData);
          self.$store.state.loadingScreen = false;
          self.notifyVue('Error', 'error');
        },
      });
    },
    submitPersonalityTest: function() {
      let self = this;
      let url = self.getBaseUrl() + '/recommendations/score_test_2/';
      const type = 'POST';
      self.$store.state.loadingScreen = true;
      let data = JSON.parse(JSON.stringify(self.personalityTestQuestions));

      $.ajax({
        beforeSend: function(request) {
          request.setRequestHeader('Authorization',
              'Token ' + self.getAuthToken());
        },
        url,
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify(data),
        type: type,
        success: function(response) {
          // console.log(response);
          response;
          // self.$router.push({name: 'YooniFit'});
          self.$store.state.loadingScreen = false;
          self.$store.state.interestFitCompleted = true;
          self.$emit('testCompleted');
        },
        error: function(errorData) {
          console.log(errorData);
          self.$store.state.loadingScreen = false;
          self.notifyVue('Error', 'error');
        },
      });
    },
    startTest: function(type, from = '') {
      // this.$store.state.loadingScreen = true;
      if (type === 'points_predict') {
        if (from === 'skillsvista_fit') {
          this.$emit('showPointsPredict');
          // this.$router.push(
          //     {name: 'PointsPredict', params: {routedFrom: 'skillsvista_fit'}});
        } else {
          this.$router.push({name: 'PointsPredict'});
        }
      } else if (type === 'career_fit') {
        this.fetchCareersQuestions();
      } else {
        this.fetchPersonalityQuestions();
      }
    },
    fetchPersonalityQuestions: function() {
      const self = this;
      let url = '';
      url = self.getBaseUrl() + '/recommendations/personality_questions/list/';
      const type = 'GET';
      $.ajax({
        beforeSend: function(request) {
          request.setRequestHeader('Authorization',
              'Token ' + self.getAuthToken());
        },
        url,
        type: type,
        success: function(response) {
          self.setPersonalityTestQuestions(response);
          self.showPersonalityFitQuiz = true;
          self.$emit('showPersonalityTest');
          self.$store.state.loadingScreen = false;
        },
        error: function(errorData) {
          console.log(errorData);
          self.notifyVue('Upload unsuccessfully.', 'error');
          self.$store.state.loadingScreen = false;
        },
      });
    },
    fetchCareersQuestions: function() {
      const self = this;
      let url = '';
      url = self.getBaseUrl() + '/recommendations/careers_questions/list/';
      const type = 'GET';
      $.ajax({
        beforeSend: function(request) {
          request.setRequestHeader('Authorization',
              'Token ' + self.getAuthToken());
        },
        url,
        type: type,
        success: function(response) {
          // if (self.isMobile()) {
          //   response = response.map(function(value) {
          //     value.score = self.sliderValues[value.score];
          //     return value;
          //   });
          // }
          self.setCareerTestQuestions(response);
          self.$store.state.loadingScreen = false;
          self.showCareerFitQuiz = true;
          self.$emit('showCareerTest');
        },
        error: function(errorData) {
          self.$store.state.loadingScreen = false;
          console.log(errorData);
          self.notifyVue('Upload unsuccessfully.', 'error');
        },
      });
    },
    toggleShowQuiz: function() {
      const self = this;
      self.showTakeQuiz = !self.showTakeQuiz;
    },
  },
};
export default yooniFitMixins;