<template>
    <div>
        <el-row style="width: 98%; margin: 0 auto;">
            <el-col :span="21" class="component-title-1">
                Unlock Your Skillsvista Fit and find your perfect course
            </el-col>
        </el-row>
        <el-row :style="!isMobile()?{height: '17vh'}:{height: 'auto'}" style="background: #3A3F5C;
                        border: 1px solid #EEEEEE;
                        border-radius: 3px;
                        padding: 2vh 2vw;
                        width: 98%;
                        margin: 0 auto;">
            <el-col :lg="6" :md="24" :sm="24" :xl="6" :xs="24">
                <div class="benefitTitle">Benefits of signing up:</div>
            </el-col>
            <el-col :lg="14" :md="24" :sm="24" :xl="14" :xs="24">
                <ul>
                    <li class="benefitDesc">
                        Course Fit report - your top 5 course recommendations in every major Irish college
                    </li>
                    <li class="benefitDesc">
                        Career Fit report - your top 5 best-fit career areas
                    </li>
                    <li class="benefitDesc">
                        Personality Fit report - your personality type, ranked in 6 areas
                    </li>
                    <li class="benefitDesc">
                        Your compatibility with over 1,000 careers and college courses
                    </li>
                </ul>
            </el-col>
            <el-col :lg="4" :md="24" :sm="24" :xl="4" :xs="24"
                    style="display: inline-block; vertical-align: middle; padding: 2vw 0;">
                <button @click="getUserLoggedIn ? $router.push({path: '/payment/', query: { redirect: $route.fullPath }}):$router.push({path: '/login/', query: { redirect: $route.fullPath }})"
                        class="unlock-button unlock-button--text"
                        v-if="!getIsPremiumUser">
                    <span v-if="!getUserLoggedIn">Login</span>
                    <span v-else-if="!getIsPremiumUser">Unlock</span>
                </button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
  export default {
    name: 'UnlockBanner',
  };
</script>

<style scoped>

</style>