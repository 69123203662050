<template>
    <div style="padding: 16px;">
        <!--        <el-row style="padding: 16px 0; text-align: left; margin: 1vh 0;" v-if="getCompulsorySubjects.length !== 0">-->
        <!--            <div class="salary-head">-->
        <!--                Enter details of mandatory subjects-->
        <!--            </div>-->
        <!--        </el-row>-->
        <!--        <el-row style="padding: 16px 1.5vh;" v-if="getCompulsorySubjects.length !== 0">-->
        <!--            <el-carousel-->
        <!--                    :style="isMobile() ? {width: '100%'}:{width: '90%'}"-->
        <!--                    id="subjectCarousel" ref="selectSubjects" direction="horizontal" :autoplay="false"-->
        <!--                    :trigger="'click'">-->
        <!--                <el-carousel-item v-for="(item, index) in getCompulsorySubjects" :key="index"-->
        <!--                                  style="padding: 15px;">-->
        <!--                    <el-row>-->
        <!--                        <div style="display: flex;-->
        <!--                                    flex-direction: row;-->
        <!--                                    flex-wrap: wrap;-->
        <!--                                    align-items: baseline;">-->
        <!--                            <div class="mandatoryLanguageText" v-html="item.text"-->
        <!--                                 :style="isMobile() ? {width: 'auto'}:{}"></div>-->
        <!--                            <div v-if="item.subject_name === 'IRISH' || item.subject_name === 'IRISH (FOUNDATION LEVEL)'">-->
        <!--                                <el-checkbox-->
        <!--                                        v-model="exemptIrish"-->
        <!--                                        :checked="getIsIrishExempted" @change="toggleIsIrishExempted"-->
        <!--                                        style="color: rgb(224, 126, 37);">-->
        <!--                                    Exempt-->
        <!--                                </el-checkbox>-->
        <!--                            </div>-->
        <!--                            <div v-if="item.subject_name === 'Languages'">-->
        <!--                                <el-checkbox-->
        <!--                                        v-model="exemptLanguages"-->
        <!--                                        :checked="getIsLanguagesExempted" @change="toggleIsLanguagesExempted"-->
        <!--                                        style="color: rgb(224, 126, 37);">-->
        <!--                                    Exempt-->
        <!--                                </el-checkbox>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                        <div style="display: flex; flex-direction: row; padding: 0 1.5vh;">-->
        <!--                            <el-radio-group-->
        <!--                                    v-if="item.subject_name !== 'Languages'"-->
        <!--                                    style="margin-top: 1vh;"-->
        <!--                                    v-model="currentSubject" size="medium">-->
        <!--                                <el-radio-button-->
        <!--                                        :label="item.subject_name" border>-->
        <!--                                    {{item.subject_name}}-->
        <!--                                </el-radio-button>-->
        <!--                                <el-radio-button-->
        <!--                                        v-if="item.subject_name_other !== ''"-->
        <!--                                        :label="item.subject_name_other" border>-->
        <!--                                    {{item.subject_name_other}}-->
        <!--                                </el-radio-button>-->
        <!--                            </el-radio-group>-->
        <!--                            <el-select style="margin-top: 1vh;"-->
        <!--                                       :style="!isMobile()?{width: '50%'}:{width: '100%'}"-->
        <!--                                       :title="currentSubject"-->
        <!--                                       v-else-->
        <!--                                       placeholder="&#45;&#45;Please select a language&#45;&#45;" v-model="currentSubject">-->
        <!--                                <el-option-->
        <!--                                        :key="item"-->
        <!--                                        :label="item"-->
        <!--                                        :value="item"-->
        <!--                                        v-for="item in languages">-->
        <!--                                </el-option>-->
        <!--                            </el-select>-->
        <!--                        </div>-->
        <!--                    </el-row>-->
        <!--                    <el-row style="text-align: left; margin: 10px 0;">-->
        <!--                        <el-col>-->
        <!--                            <div class="mandatoryLanguageText">Level</div>-->
        <!--                        </el-col>-->
        <!--                        <el-col :xl="10" :lg="10" :md="10" :sm="24" :xs="24">-->
        <!--                            <el-radio-group-->
        <!--                                    style="margin-top: 1vh; padding: 0 1.5vh;"-->
        <!--                                    :disabled="currentSubject === ''"-->
        <!--                                    v-model="currentLevel"-->
        <!--                                    size="medium">-->
        <!--                                <el-radio-button-->
        <!--                                        v-for="(item, index) in getLevelOptions"-->
        <!--                                        :label="item.value"-->
        <!--                                        :key="index" border>-->
        <!--                                    {{item.text}}-->
        <!--                                </el-radio-button>-->
        <!--                            </el-radio-group>-->
        <!--                        </el-col>-->
        <!--                    </el-row>-->
        <!--                    <el-row style="text-align: left; margin: 10px 0;">-->
        <!--                        <el-col>-->
        <!--                            <div class="mandatoryLanguageText">Grade</div>-->
        <!--                        </el-col>-->
        <!--                        <el-col :span="24" style="padding: 0 1.5vh;">-->
        <!--                            <el-radio-group-->
        <!--                                    style="margin-top: 1vh;"-->
        <!--                                    v-if="!isMobile()"-->
        <!--                                    v-model="currentGrade"-->
        <!--                                    @change="goNext(index, item.subject_name)"-->
        <!--                                    size="medium">-->
        <!--                                <el-radio-button-->
        <!--                                        :disabled="currentLevel === ''"-->
        <!--                                        v-for="(item, index) in getGrades"-->
        <!--                                        :label="item.value"-->
        <!--                                        :title="item.label"-->
        <!--                                        :key="index" border>-->
        <!--                                    {{item.label}}-->
        <!--                                </el-radio-button>-->
        <!--                            </el-radio-group>-->
        <!--                            <el-select style="margin-top: 1vh;"-->
        <!--                                       :style="!isMobile()?{width: '50%'}:{width: '100%'}"-->
        <!--                                       :title="currentGrade"-->
        <!--                                       v-else-->
        <!--                                       @input="goNext(index, item.subject_name)"-->
        <!--                                       placeholder="&#45;&#45;Please select a grade&#45;&#45;" v-model="currentGrade">-->
        <!--                                <el-option-->
        <!--                                        :key="item.value"-->
        <!--                                        :label="item.label"-->
        <!--                                        :value="item.value"-->
        <!--                                        v-for="item in getGrades">-->
        <!--                                </el-option>-->
        <!--                            </el-select>-->
        <!--                        </el-col>-->
        <!--                    </el-row>-->
        <!--                </el-carousel-item>-->
        <!--            </el-carousel>-->
        <!--        </el-row>-->
        <el-row v-if="getSelectedSubjectsList.length !== 0"
                style="margin: 2vh 0 0; display: flex; flex-direction: row; align-items: center;">
            <div class="salary-head">
                Your subjects and predicted grades
            </div>
        </el-row>
        <div style="display: flex; flex-direction: row; flex-wrap: wrap; align-items: center;">
            <div v-for="(subject, index) in getSelectedSubjectsList" :key="index"
                 style="padding: 16px;"
                 :style="isMobile()?{width: '100%', margin: '0'}:{width: '33.33%', margin: '24px 0 0'}"
                 v-if="subject.subject_name !== ''">

                <el-card style="padding: 25px;">
                    <el-row>
                        <div style="display: flex; flex-direction: row;">
                            <div v-if="subjectEditID !== subject.id" style="width: 100%; text-align: left"
                                 class="select-subject-display">
                                {{getSubjectName(subject.subject_name)}}
                            </div>
                            <div v-else-if="subjectEditID === subject.id" style="width: 100%; text-align: left"
                                 class="select-subject-display">
                                <el-autocomplete
                                        @input="currentLevel='', currentGrade=''"
                                        @change="checkIfCorrect"
                                        class="el-input search-input1"
                                        v-model="currentSubject"
                                        :title="selectedSubjectsList.length === 10 ? 'You can select only 10 subjects':currentSubject"
                                        placeholder="--Please select a subject--"
                                        :fetch-suggestions="querySubjectsSearchAsync"></el-autocomplete>
                            </div>
                            <el-button
                                    style="padding: 0; font-size: 22px; z-index: 99;"
                                    @click="editEntry(subject.id)"
                                    icon="el-icon-edit"
                                    type="text" v-if="!getStatus3(subject) && subjectEditID !== subject.id"></el-button>
                            <el-button
                                    style="padding: 0; font-size: 22px; z-index: 99;"
                                    @click="deleteEntry(subject.id)"
                                    type="text"
                                    icon="el-icon-delete"
                                    v-if="!getStatus3(subject) && subjectEditID !== subject.id"></el-button>
                        </div>
                    </el-row>
                    <div v-if="subjectEditID !== subject.id" class="select-subject-display"
                         style="display: flex; flex-direction: row; margin: 10px 0 0;">
                        <div v-if="!subject.subject_name.toLowerCase().includes('lcvp')">
                            Level: <span style="font-weight: 400;">{{getLevel(subject.level)}}</span>
                        </div>
                        <div :style="subject.subject_name.toLowerCase().includes('lcvp') ? {}:{'margin-left': '10px'}">
                            Grade: <span style="font-weight: 400;">{{getGradeLabel(subject.grade)}}</span>
                        </div>
                    </div>
                    <div v-else-if="subjectEditID === subject.id" style="margin: 1vh 0;">
                        <el-row :gutter="5">
                            <el-col :span="10">
                                <el-select @change="currentGrade=''" style="width: 100%; " :disabled="getStatus1()"
                                           :title="currentLevel"
                                           placeholder="--Please select a level--" v-model="currentLevel">
                                    <el-option
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                            v-for="item in getLevelOptions">
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="10">
                                <el-select style="width: 100%; " :disabled="getStatus2()"
                                           :title="currentGrade"
                                           @input="setFocusToButton"
                                           placeholder="--Please select a grade--" v-model="currentGrade">
                                    <el-option
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                            v-for="item in getGrades">
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="2">
                                <el-button ref="addButton" v-if="!getStatus4() && selectedSubjectsList.length < 10"
                                           type="success" icon="el-icon-check"
                                           @click="addEmptySubject"
                                           circle></el-button>
                            </el-col>
                            <el-col :span="2">
                                <el-button ref="cancelButton" v-if="!getStatus4() && selectedSubjectsList.length < 10"
                                           type="danger" icon="el-icon-close"
                                           @click="resetAddSubject"
                                           circle></el-button>
                            </el-col>
                        </el-row>
                    </div>
                </el-card>
            </div>
            <div v-else :style="isMobile()?{width: '100%', margin: '0'}:{width: '33.33%', margin: '1vh 0'}"
                 style="padding: 16px; ">
                <div style="
                        display: flex;
                        flex-direction: row-reverse;
                        align-content: flex-end;">
                    <div
                            :style="getCompulsorySubjects.length === 0 ? {background: '#7d90d0'}:{background: 'rgb(224, 126, 37)'}"
                            style="
                        width: fit-content;
                        color: white;
                        border: 1px solid grey;
                        padding: 1px 5px;
                        border-radius: 5px;">
                        <span v-if="getCompulsorySubjects.length === 0">New Subject</span>
                        <span v-else>Compulsory Subject</span>
                    </div>
                </div>
                <el-card
                        style="
                            padding: 15px;
                            cursor: pointer;
                            background: rgb(240, 240, 240);
                            border: 1px dashed #385c8e;">
                    <el-row v-if="getCompulsorySubjects.length === 0">
                        <el-autocomplete
                                @input="currentLevel='', currentGrade=''"
                                @change="checkIfCorrect"
                                class="el-input search-input1"
                                v-model="currentSubject"
                                :title="selectedSubjectsList.length === 10 ? 'You can select only 10 subjects':currentSubject"
                                placeholder="--Select subject name--"
                                :fetch-suggestions="querySubjectsSearchAsync"></el-autocomplete>
                    </el-row>
                    <el-row v-else>
                        <!--                        <el-col :span="getCompulsorySubjects[0].subject_name === 'IRISH' ? 20: 24">-->
                        <!--                            <el-input disabled v-model="currentSubject=getCompulsorySubjects[0].subject_name"></el-input>-->
                        <!--                        </el-col>-->
                        <!--                        -->
                        <div style="display: flex; flex-direction: row; align-items: center;">
                            <el-radio-group
                                    v-if="getCompulsorySubjects[0].subject_name !== 'Languages'"
                                    style="margin-top: 1vh;"
                                    v-model="currentSubject" size="medium">
                                <el-radio-button
                                        :label="getCompulsorySubjects[0].subject_name" border>
                                    {{getCompulsorySubjects[0].subject_name}}
                                </el-radio-button>
                                <el-radio-button
                                        v-if="getCompulsorySubjects[0].subject_name_other !== ''"
                                        :label="getCompulsorySubjects[0].subject_name_other" border>
                                    {{getCompulsorySubjects[0].subject_name_other}}
                                </el-radio-button>
                            </el-radio-group>
                            <!--                            <el-select style="margin-top: 1vh;"-->
                            <!--                                       :style="!isMobile()?{width: '50%'}:{width: '100%'}"-->
                            <!--                                       :title="currentSubject"-->
                            <!--                                       v-else-->
                            <!--                                       placeholder="&#45;&#45;Please select a language&#45;&#45;" v-model="currentSubject">-->
                            <!--                                <el-option-->
                            <!--                                        :key="item"-->
                            <!--                                        :label="item"-->
                            <!--                                        :value="item"-->
                            <!--                                        v-for="item in languages">-->
                            <!--                                </el-option>-->
                            <!--                            </el-select>-->
                            <div style="margin-left: 1vw; margin-top: 5px;"
                                 v-if="getCompulsorySubjects[0].subject_name === 'IRISH' || getCompulsorySubjects[0].subject_name === 'IRISH (FOUNDATION LEVEL)'">
                                <el-checkbox
                                        v-model="exemptIrish"
                                        :checked="getIsIrishExempted" @change="toggleIsIrishExempted"
                                        style="color: rgb(224, 126, 37);">
                                    Exempt
                                </el-checkbox>
                            </div>
                        </div>
                    </el-row>
                    <el-row :gutter="5" style="margin-top: 1vh;">
                        <el-col :xl="10" :lg="10" :md="10" :sm="24" :xs="24">
                            <el-select @change="currentGrade=''" style="width: 100%; height: 40px;" :disabled="getStatus1()"
                                       :title="currentLevel"
                                       placeholder="--Select a grade--" v-model="currentLevel">
                                <el-option
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                        v-for="item in getLevelOptions">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :xl="10" :lg="10" :md="10" :sm="24" :xs="24" :style="isMobile() ?{marginTop: '1vh'}:{}">
                            <el-select style="width: 100%; " :disabled="getStatus2()"
                                       :title="currentGrade"
                                       @input="setFocusToButton"
                                       placeholder="--Select a level--" v-model="currentGrade">
                                <el-option
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                        v-for="item in getGrades">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :xl="{span: 2, offset: 0}" :lg="{span: 2, offset: 0}" :md="{span: 2, offset: 0}"
                                :sm="{span: 3, offset: 9}" :xs="{span: 3, offset: 9}"
                                :style="isMobile() ?{marginTop: '1vh'}:{}">
                            <el-button ref="addButton" v-if="!getStatus4() && selectedSubjectsList.length < 10"
                                       type="success" icon="el-icon-check"
                                       @click="addEmptySubject"
                                       circle></el-button>
                        </el-col>
                        <el-col :xl="{span: 2, offset: 0}" :lg="{span: 2, offset: 0}" :md="{span: 2, offset: 0}"
                                :sm="{span: 3, offset: 0}" :xs="{span: 3, offset: 0}"
                                :style="isMobile() ?{marginTop: '1vh'}:{}">
                            <el-button ref="cancelButton" v-if="!getStatus4() && selectedSubjectsList.length < 10"
                                       type="danger" icon="el-icon-close"
                                       @click="resetAddSubject"
                                       circle></el-button>
                        </el-col>
                    </el-row>
                </el-card>
            </div>
        </div>

        <el-row style="padding: 16px; width: 100%; text-align: left; color: rgb(224, 126, 37);">
            <el-col :xl="18" :lg="18" :md="18" :sm="24" :xs="24">
                <el-row>
                    <el-col :xl="4" :lg="4" :md="4" :sm="24" :xs="24">
                        <el-checkbox
                                v-model="exemptIrish"
                                :checked="getIsIrishExempted" @change="toggleIsIrishExempted"
                                style="color: rgb(224, 126, 37);">
                            Exempt from Irish
                        </el-checkbox>
                    </el-col>
                    <el-col :xl="4" :lg="4" :md="4" :sm="24" :xs="24">
                        <el-checkbox
                                v-model="exemptLanguages"
                                :checked="getIsLanguagesExempted" @change="toggleIsLanguagesExempted"
                                style="color: rgb(224, 126, 37);">
                            Exempt from Languages
                        </el-checkbox>
                    </el-col>
                    <el-col :span="24" style="text-align: left;">
                        <el-checkbox v-model="doubleChecked" style="color: rgb(224, 126, 37);">
                            <span class='termsCondition' style="color: white;">Please double check that ALL subjects are filled in correctly before final submission*</span>
                        </el-checkbox>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :xl="6" :lg="6" :md="6" :sm="24" :xs="24"
                    style="display: flex; flex-direction: row-reverse; align-items: center; flex-wrap: wrap; ">
                <el-row>
                    <el-button :disabled="!showPredictButton"
                               :title="getButtonTitle"
                               @click="predictCAOPoints"
                               style="margin-top: 0 !important;"
                               class="black-primary-button takeQuizButton">
                        <span>Submit Predictions</span>
                    </el-button>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>

  import $ from 'jquery';
  import {mapActions} from 'vuex';

  export default {
    name: 'SelectSubjectsNew',
    props: ['routedFrom'],
    data: function() {
      return {
        exemptIrish: false,
        exemptLanguages: false,
        compulsorySubjects: [
          {
            'text': 'Select your grades for ',
            'subject_name': 'ENGLISH',
            'subject_name_other': '',
            'level': '',
            'grade': '',
          },
          {
            'text': 'Select either of the options',
            'subject_name': 'MATHEMATICS',
            'subject_name_other': 'MATHEMATICS (FOUNDATION LEVEL)',
            'level': '',
            'grade': '',
          },
          {
            'text': 'Select either of the options',
            'subject_name': 'IRISH',
            'subject_name_other': 'IRISH (FOUNDATION LEVEL)',
            'level': '',
            'grade': '',
          },
          // {
          //   'text': 'Select another language',
          //   'subject_name': 'Languages',
          //   'subject_name_other': '',
          //   'level': '',
          //   'grade': '',
          // },
        ],
        doubleChecked: false,
        mathsSelected: false,
        englishSelected: false,
        languageSelected: false,
        irishSelected: false,
        subjectEditID: false,
        currentSubject: '',
        currentLevel: '',
        currentGrade: '',
        selectedSubjectsList: [],
        gradeMapping: {
          'O1': 'O1 (90-100%)',
          'O2': 'O2 (80-89%)',
          'O3': 'O3 (70-79%)',
          'O4': 'O4 (60-69%)',
          'O5': 'O5 (50-59%)',
          'O6': 'O6 (40-49%)',
          'O7': 'O7 (30-39%)',
          'O8': 'O8 (0-30%)',
          'H1': 'H1 (90-100%)',
          'H2': 'H2 (80-89%)',
          'H3': 'H3 (70-79%)',
          'H4': 'H4 (60-69%)',
          'H5': 'H5 (50-59%)',
          'H6': 'H6 (40-49%)',
          'H7': 'H7 (30-39%)',
          'H8': 'H8 (0-30%)',
          'F1': 'F1',
          'F2': 'F2',
          '1': 'DISTINCTION',
          '2': 'MERIT',
          '3': 'PASS',
        },
        subjectOptions: [
          {value: 'ACCOUNTING', text: 'ACCOUNTING'},
          {value: 'AGRICULTURAL ECONOMICS', text: 'AGRICULTURAL ECONOMICS'},
          {value: 'AGRICULTURAL SCIENCE', text: 'AGRICULTURAL SCIENCE'},
          {value: 'ANCIENT GREEK', text: 'ANCIENT GREEK'},
          {value: 'APPLIED MATHEMATICS', text: 'APPLIED MATHEMATICS'},
          {value: 'ARABIC', text: 'ARABIC'},
          {value: 'ART', text: 'ART'},
          {value: 'BIOLOGY', text: 'BIOLOGY'},
          {value: 'BUSINESS', text: 'BUSINESS'},
          {value: 'CHEMISTRY', text: 'CHEMISTRY'},
          {value: 'CLASSICAL STUDIES', text: 'CLASSICAL STUDIES'},
          {value: 'COMPUTER SCIENCE', text: 'COMPUTER SCIENCE'},
          {value: 'CONSTRUCTION STUDIES', text: 'CONSTRUCTION STUDIES'},
          {value: 'DESIGN & COMMUNICATION GRAPHIC', text: 'DESIGN & COMMUNICATION GRAPHIC'},
          {value: 'DUTCH', text: 'DUTCH'},
          {value: 'ECONOMICS', text: 'ECONOMICS'},
          {value: 'ENGLISH', text: 'ENGLISH'},
          {value: 'ENGINEERING', text: 'ENGINEERING'},
          {value: 'FRENCH', text: 'FRENCH'},
          {value: 'GERMAN', text: 'GERMAN'},
          {value: 'GEOGRAPHY', text: 'GEOGRAPHY'},
          {value: 'HEBREW STUDIES', text: 'HEBREW STUDIES'},
          {value: 'HISTORY', text: 'HISTORY'},
          {value: 'HOME ECONOMICS', text: 'HOME ECONOMICS'},
          {value: 'IRISH', text: 'IRISH'},
          {value: 'IRISH (FOUNDATION LEVEL)', text: 'IRISH (FOUNDATION LEVEL)'},
          {value: 'ITALIAN', text: 'ITALIAN'},
          {value: 'JAPANESE', text: 'JAPANESE'},
          {value: 'LATIN', text: 'LATIN'},
          {value: 'LCVP LINK MODULES', text: 'LCVP LINK MODULES'},
          {value: 'MATHEMATICS', text: 'MATHEMATICS'},
          {value: 'MATHEMATICS (FOUNDATION LEVEL)', text: 'MATHEMATICS (FOUNDATION LEVEL)'},
          {value: 'MUSIC', text: 'MUSIC'},
          {value: 'OTHER EU LANGUAGE', text: 'OTHER EU LANGUAGE'},
          {value: 'OTHER FOREIGN LANGUAGE', text: 'OTHER FOREIGN LANGUAGE'},
          {value: 'PHYSICAL EDUCATION', text: 'PHYSICAL EDUCATION'},
          {value: 'PHYSICS AND CHEMISTRY', text: 'PHYSICS AND CHEMISTRY'},
          {value: 'PHYSICS', text: 'PHYSICS'},
          {value: 'POLISH', text: 'POLISH'},
          {value: 'POLITICS AND SOCIETY', text: 'POLITICS AND SOCIETY'},
          {value: 'PORTUGUESE', text: 'PORTUGUESE'},
          {value: 'RELIGIOUS EDUCATION', text: 'RELIGIOUS EDUCATION'},
          {value: 'RUSSIAN', text: 'RUSSIAN'},
          {value: 'SPANISH', text: 'SPANISH'},
          {value: 'TECHNOLOGY', text: 'TECHNOLOGY'},
        ],
        levelOptions: [
          {value: 'HIGHER', text: 'HIGHER'},
          {value: 'ORDINARY', text: 'ORDINARY'},
        ],
        levelOptionsFoundation: [
          {value: 'FOUNDATION', text: 'FOUNDATION'},
        ],
        higherGradeOptions: [
          {value: 'H1', label: 'H1 (90-100%)'},
          {value: 'H2', label: 'H2 (80-89%)'},
          {value: 'H3', label: 'H3 (70-79%)'},
          {value: 'H4', label: 'H4 (60-69%)'},
          {value: 'H5', label: 'H5 (50-59%)'},
          {value: 'H6', label: 'H6 (40-49%)'},
          {value: 'H7', label: 'H7 (30-39%)'},
          {value: 'H8', label: 'H8 (0-30%)'},
        ],
        foundationGradeOptions: [
          {value: 'F1', label: 'F1'},
          {value: 'F2', label: 'F2'},
        ],
        ordGradeOptions: [
          {value: 'O1', label: 'O1 (90-100%)'},
          {value: 'O2', label: 'O2 (80-89%)'},
          {value: 'O3', label: 'O3 (70-79%)'},
          {value: 'O4', label: 'O4 (60-69%)'},
          {value: 'O5', label: 'O5 (50-59%)'},
          {value: 'O6', label: 'O6 (40-49%)'},
          {value: 'O7', label: 'O7 (30-39%)'},
          {value: 'O8', label: 'O8 (0-30%)'},
        ],
        foundationGrades: [
          {value: 'F1', label: 'F1'},
          {value: 'F2', label: 'F2'},
        ],
        lcvpGrades: [
          {value: '1', label: 'DISTINCTION'},
          {value: '2', label: 'MERIT'},
          {value: '3', label: 'PASS'},
        ],
        languages: [
          'FRENCH',
          'GERMAN',
          'ARABIC',
          'DUTCH',
          'HEBREW STUDIES',
          'ITALIAN',
          'JAPANESE',
          'LATIN',
          'OTHER EU LANGUAGE',
          'OTHER FOREIGN LANGUAGE',
          'POLISH',
          'PORTUGUESE',
          'RUSSIAN',
          'SPANISH',
        ],
      };
    },
    computed: {
      getButtonTitle: function() {
        if (this.selectedSubjectsList.length < 7)
          return 'Select atleast 6 subjects';
        else if (!this.doubleChecked)
          return 'Accept the checkbox';
        return '';
      },
      showPredictButton: function() {
        return this.selectedSubjectsList.length > 6 && this.doubleChecked;
      },
      getCompulsorySubjects: function() {
        let self = this;
        $('.el-carousel__indicators').remove();
        $('.el-carousel__arrow--right').remove();
        $('.el-carousel__arrow').remove();
        return this.compulsorySubjects.filter(function(element) {
          let subject = element.subject_name.toUpperCase();
          let filteredIndex = self.selectedSubjectsList.findIndex(function(data) {
            if (subject === 'LANGUAGES') {
              return self.languages.includes(data.subject_name.toUpperCase());
            } else {
              return data.subject_name.toUpperCase().includes(subject) &&
                  !data.subject_name.toUpperCase().includes('APPLIED');
            }
          });
          if (filteredIndex === -1) {
            if (subject.includes('IRISH') && self.exemptIrish) {
              return false;
            } else if (subject.includes('LANGUAGES') && self.exemptLanguages) {
              return false;
            }
            return true;
          }
        });
      },
      getSelectedSubjectsList: function() {
        return this.selectedSubjectsList;
      },
      getLevelOptions: function() {
        if (this.currentSubject.toLowerCase().includes('foundation')) {
          return this.levelOptionsFoundation;
        }
        return this.levelOptions;
      },
      getGrades: function() {
        if (this.currentSubject.toUpperCase() === 'LCVP LINK MODULES') {
          return this.lcvpGrades;
        } else {
          if (this.currentLevel === 'HIGHER') {
            return this.higherGradeOptions;
          } else if (this.currentLevel === 'FOUNDATION') {
            return this.foundationGrades;
          } else {
            return this.ordGradeOptions;
          }
        }
      },
    },
    mounted() {
      let self = this;
      self.fetchSubjectsNew();
      self.checkUserLoggedIn().then(response => {
        self.$store.state.loadingScreen = false;
      }).catch(error => {
        self.$store.state.loadingScreen = false;
      });
      self.exemptIrish = self.$store.state.exemptIrish;
      self.exemptLanguages = self.$store.state.exemptLanguages;
    },
    methods: {
      ...mapActions(['setExemptLanguages', 'setExemptIrish', 'setPredictedCAOPoints']),

      predictCAOPoints: function() {
        let self = this;
        self.irishSelected = false;
        self.mathsSelected = false;
        self.englishSelected = false;
        self.languageSelected = false;
        self.selectedSubjectsList.filter(function(element) {
          if (element.subject_name.toLowerCase() === 'irish' || element.subject_name.toLowerCase() ===
              'irish (foundation level)')
            self.irishSelected = true;
          if (element.subject_name.toLowerCase() === 'mathematics (foundation level)' ||
              element.subject_name.toLowerCase() ===
              'mathematics') {
            self.mathsSelected = true;
          }
          if (element.subject_name.toLowerCase() === 'english') {
            self.englishSelected = true;
          }
          if (self.languages.includes(element.subject_name.toUpperCase())) {
            self.languageSelected = true;
          }
        });
        if (!self.englishSelected) {
          self.$message({
            title: 'Unsuccessful',
            message: 'Please select English to continue',
            type: 'error',
            duration: 3000,
            showClose: true,
          });
          return false;
        }
        if (!self.mathsSelected) {
          self.$message({
            title: 'Unsuccessful',
            message: 'Please select Mathematics to continue',
            type: 'error',
            duration: 3000,
            showClose: true,
          });
          return false;
        }
        if (!self.irishSelected && !self.exemptIrish) {
          self.$message({
            title: 'Unsuccessful',
            message: 'Please select Irish or tick \'Exempt from Irish\' checkbox to continue',
            type: 'error',
            duration: 3000,
            showClose: true,
          });
          return false;
        }
        if (self.irishSelected && self.exemptIrish) {
          self.$message({
            title: 'Unsuccessful',
            message: 'You cannot select Irish as a subject AND select \'Exempt from Irish\'',
            type: 'error',
            duration: 3000,
            showClose: true,
          });
          return false;
        }
        if (self.languageSelected && self.exemptLanguages) {
          self.$message({
            title: 'Unsuccessful',
            message: 'You cannot select another language as a subject AND select \'Exempt from Languages\'',
            type: 'error',
            duration: 3000,
            showClose: true,
          });
          return false;
        }
        // if (!self.languageSelected && !self.exemptLanguages) {
        //   self.$message({
        //     title: 'Unsuccessful',
        //     message: 'Please select any other language or tick \'Exempt from Languages\' checkbox to continue',
        //     type: 'error',
        //     duration: 3000,
        //     showClose: true,
        //   });
        //   return false;
        // }
        self.setExemptLanguages(self.exemptLanguages);
        self.setExemptIrish(self.exemptIrish || self.exemptLanguages);

        let url = self.getBaseUrl() + '/recommendations/predict_cao_points/';
        const type = 'POST';
        let requestData = {
          'exempt_irish': self.exemptIrish || self.exemptLanguages,
          'exempt_languages': self.exemptLanguages,
        };
        $.ajax({
          beforeSend: function(request) {
            request.setRequestHeader('Authorization',
                'Token ' + self.getAuthToken());
          },
          url,
          data: JSON.stringify(requestData),
          type: type,
          contentType: 'application/json',
          success: function(response) {
            self.setPredictedCAOPoints(response.predicted_score);
            self.notifyVue('Your predicted CAO points are ' + response.predicted_score, 'success');
            if (self.routedFrom === 'skillsvista_fit') {
              self.$store.state.predictionsSubmitted = true;
              self.$emit('caoPointsSubmitted');
            }
          },
          error: function(errorData) {
            errorData;
            self.notifyVue('Error occurred while predicting points', 'error');
          },
        });
      },
      goNext: function(index, subjectName) {
        if (subjectName.toLowerCase() === 'english')
          this.currentSubject = subjectName;
        this.addEmptySubject();
        this.$refs.selectSubjects.setActiveItem(index + 1);
      },
      toggleIsIrishExempted: function() {
        let self = this;
        self.setExemptIrish(!self.$store.state.exemptIrish);
        if (self.$store.state.exemptIrish) {
          let filteredData = self.selectedSubjectsList.filter(
              element => element.subject_name.toUpperCase() === 'IRISH' || element.subject_name.toUpperCase() ===
                  'IRISH (FOUNDATION LEVEL)');
          filteredData.forEach(element => self.deleteEntry(element.id));
          self.currentSubject = '';
        }
      },
      toggleIsLanguagesExempted: function() {
        let self = this;
        self.setExemptLanguages(!self.$store.state.exemptLanguages);
        if (self.$store.state.exemptLanguages) {
          let filteredData = self.selectedSubjectsList.filter(
              element => self.languages.includes(element.subject_name.toUpperCase()));
          filteredData.forEach(element => self.deleteEntry(element.id));
        }
      },
      resetAddSubject: function() {
        this.currentLevel = '';
        this.currentGrade = '';
        this.currentSubject = '';
        this.subjectEditID = false;
      },
      addEmptySubject: function() {
        let self = this;
        if (self.selectedSubjectsList.length > 0) {
          let lastSubjectSelected = self.selectedSubjectsList[self.selectedSubjectsList.length - 1].subject_name;
          self.subjectOptions = self.subjectOptions.filter(el => el.value !== lastSubjectSelected);
        }
        if (self.currentSubject === 'MATHEMATICS' || self.currentSubject === 'MATHEMATICS (FOUNDATION LEVEL)')
          this.mathsSelected = true;
        if (self.currentSubject === 'IRISH' || self.currentSubject === 'IRISH (FOUNDATION LEVEL)') {
          this.irishSelected = true;
          this.$store.state.exemptIrish = false;
        }
        if (self.languages.includes(self.currentSubject.toUpperCase())) {
          this.languageSelected = true;
          this.$store.state.exemptLanguages = false;
        }

        self.saveSubject().then(response => {
          self.selectedSubjectsList.push({
            'subject_name': self.currentSubject,
            'level': self.currentLevel,
            'grade': self.currentGrade,
            'id': response,
          });
          self.selectedSubjectsList.sort((a, b) => (a.subject_name > b.subject_name) ? 1 : -1);
          self.addEmpty();
          self.currentSubject = '';
          self.currentLevel = '';
          self.currentGrade = '';
        }).catch(reject => {
        });
      },
      setFocusToButton: function() {
        // let self = this;
        // if (!self.subjectEditID) {
        //   self.$nextTick(function() {
        //     self.$refs.addButton.focus();
        //   });
        // }
      },
      getSubjects: function() {
        let self = this;
        let subjectsNames = self.selectedSubjectsList.map(function(value) {
          return value.subject_name.toLowerCase();
        });
        
        if ((subjectsNames.includes('irish') || subjectsNames.includes('irish (foundation level)')) 
            || this.exemptIrish){
            subjectsNames.push('irish');
            subjectsNames.push('irish (foundation level)');
          }
        if (subjectsNames.includes('mathematics (foundation level)') || subjectsNames.includes('mathematics')) {
            subjectsNames.push('mathematics (foundation level)');
            subjectsNames.push('mathematics');
        }
        if(this.exemptLanguages){
          let languageList = this.languages.map(function(value) {
            return value.toLowerCase();
          });
          subjectsNames.push(...languageList);
        }
        
        return self.subjectOptions.filter(function(element) {
          if (!subjectsNames.includes(element.text.toLowerCase()))
            return element;
        });
      },
      querySubjectsSearchAsync: function(queryString, cb) {
        let programList = [];
        let subjectList = this.getSubjects();
        for (let i = 0; i < subjectList.length; i++) {
          programList.push({
            value: subjectList[i].value,
          });
        }
        this.getSearchFilterResults(programList, queryString, cb);
      },
      getSearchFilterResults: function(programList, queryString, cb) {
        let results = queryString
            ? programList.filter(this.createStateFilter(queryString))
            : programList;
        //The basic idea is to implement a filter that filters all the data that exists in your input field.
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          cb(results);
        }, 1000 * Math.random());
      },
      createStateFilter(queryString) {
        return (program) => {
          return (
              program.value.toLowerCase().indexOf(queryString.toLowerCase()) !== -1
          );
        };
      },
      checkIfCorrect: function() {
        let self = this;
        let filteredData = self.subjectOptions.filter(function(element) {
          if (element.value === self.currentSubject)
            return element;
        });
        if (filteredData.length === 0)
          self.currentSubject = '';
      },
      fetchSubjectsNew: function() {
        let self = this;
        let url = self.getBaseUrl() + '/recommendations/subjects/list/';
        if (self.getAuthToken() !== '') {
          $.ajax({
            beforeSend: function(request) {
              request.setRequestHeader('Authorization',
                  'Token ' + self.getAuthToken());
            },
            url,
            type: 'GET',
            success: function(response) {
              if (response.length > 0) {
                self.$nextTick(function() {
                  self.selectedSubjectsList = response;
                  self.selectedSubjectsList.forEach(function(element) {
                    let subjectName = element.subject_name.toLowerCase();
                    if (subjectName === 'mathematics' || subjectName === 'mathematics (foundation level)')
                      self.mathsSelected = true;
                    else if (subjectName === 'irish' || subjectName === 'irish (foundation level)')
                      self.irishSelected = true;
                    else if (subjectName === 'english')
                      self.englishSelected = true;
                    else if (self.languages.includes(subjectName.toUpperCase()))
                      self.languageSelected = true;
                  });
                  self.addEmpty();
                });
              } else {
                self.selectedSubjectsList = [
                  {
                    'subject_name': '',
                    'level': '',
                    'grade': '',
                  }];
              }
            },
            error: function() {
              self.fillEmptySubjects(self);
            },
          });
        } else {
          self.fillEmptySubjects(self);
        }
      },
      saveSubject: function() {
        let self = this;
        self.$store.state.loadingScreen = true;
        return new Promise(function(resolve, reject) {
          let url = self.getBaseUrl() + '/recommendations/subject/create/';
          let requestData = {
            subject_name: self.currentSubject,
            grade: self.currentGrade,
            level: self.currentLevel,
          };
          const type = 'POST';
          $.ajax({
            beforeSend: function(request) {
              request.setRequestHeader('Authorization',
                  'Token ' + self.getAuthToken());
            },
            url,
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify(requestData),
            type: type,
            success: function(response) {
              self.$store.state.predictedCAOPoints = false;
              if (self.subjectEditID) {
                self.selectedSubjectsList = self.selectedSubjectsList.filter(function(element) {
                  return element.id !== self.subjectEditID;
                });
                self.deleteEntry(self.subjectEditID);
              }
              self.selectedSubjectsList = self.selectedSubjectsList.filter(function(element) {
                return element.subject_name !== '';
              });
              self.$store.state.loadingScreen = false;
              resolve(response['id']);
            },
            error: function(error) {
              error;
              reject();
            },
          });
        });
      },
      editEntry: function(id) {
        let self = this;
        self.subjectEditID = id;
        self.selectedSubjectsList = self.selectedSubjectsList.filter(function(element) {
          if (element.id === id) {
            self.currentLevel = element.level;
            self.currentSubject = element.subject_name;
            self.currentGrade = self.gradeMapping[element.grade];
          }
          return element.subject_name !== '';
        });
      },
      deleteEntry: function(id) {
        let self = this;
        self.$store.state.loadingScreen = true;
        return new Promise(function(resolve, reject) {
          let url = self.getBaseUrl() + `/recommendations/subject/delete/${id}/`;
          let type = 'DELETE';
          $.ajax({
            beforeSend: function(request) {
              request.setRequestHeader('Authorization',
                  'Token ' + self.getAuthToken());
            },
            url: url,
            type: type,
            success: function(response) {
              response;
              if (self.selectedSubjectsList.length === 1) {
                self.addEmpty();
              }
              let subjectName;
              if (!self.subjectEditID) {
                let filteredIndex = self.selectedSubjectsList.findIndex(element => element.id === id);
                subjectName = self.selectedSubjectsList[filteredIndex].subject_name.toLowerCase();
                if (subjectName === 'irish' || subjectName === 'irish (foundation level)') {
                  self.irishSelected = false;
                }
                if (subjectName === 'mathematics' || subjectName === 'mathematics (foundation level)') {
                  self.mathsSelected = false;
                }
                self.$nextTick(function() {
                  self.selectedSubjectsList.splice(filteredIndex, 1);
                });
              }
              self.$store.state.predictedCAOPoints = false;
              self.$store.state.loadingScreen = false;
              self.subjectEditID = false;

              resolve();
            },
            error: function(error) {
              reject(error);
              self.$store.state.loadingScreen = false;
            },
          });
        });

      },
      getStatus1: function() {
        if (this.currentSubject.toUpperCase() === 'LCVP LINK MODULES') {
          return true;
        }
        return this.currentSubject === '' || this.selectedSubjectsList.length === 10;

      },
      getStatus2: function() {
        if (this.currentSubject.toUpperCase() === 'LCVP LINK MODULES') {
          return false;
        }
        return this.currentSubject === '' || this.currentLevel === '';
      },
      getStatus3: function(subjectItem) {
        if (subjectItem.subject_name.toUpperCase() === 'LCVP LINK MODULES' && subjectItem.grade !== '') {
          return false;
        }
        return (subjectItem.subject_name === '' || subjectItem.level === '' || subjectItem.grade === '');
      },
      getStatus4: function() {
        if (this.currentSubject.toUpperCase() === 'LCVP LINK MODULES' && this.currentGrade !== '') {
          return false;
        }
        return (this.currentSubject === '' || this.currentLevel === '' || this.currentGrade === '');
      },
      getSubjectName: function(subjectName) {
        return subjectName.toLowerCase().replace(/\b\w/g, l => l.toUpperCase());
      },
      getLevel: function(level) {
        return level.toLowerCase().replace(/\b\w/g, l => l.toUpperCase());
      },
      getGradeLabel: function(grade) {
        return this.gradeMapping[grade.toString().toUpperCase()].toLowerCase().replace(/\b\w/g, l => l.toUpperCase());
      },
      addEmpty: function() {
        let temp = {
          'subject_name': '',
          'grade': '',
          'level': '',
        };
        this.selectedSubjectsList.splice(0, 0, temp);
        // this.selectedSubjectsList.push(temp);
      },
    },
  };
</script>

<style>
    /*.el-carousel__indicators, .el-carousel__arrow--right, .el-carousel__arrow {*/
    /*    display: none;*/
    /*}*/
</style>
<style scoped>
    #subjectCarousel {
        background: rgb(240, 240, 240);
        border: 1px dashed grey;
    }

    .select-subject-display {
        opacity: 0.8;
    }
</style>