<template>
    <div>
        <!-- <span class="course-search" v-if="!isMobile()">{{searchBarLabel}}</span> -->
        <el-form class="demo-form-inline">
            <!-- <el-form-item :label="searchBarLabel"></el-form-item> -->
            <el-row>
                <el-col :lg="20" :md="18" :sm="18" :xl="20" :xs="18">
                    <el-autocomplete
                            @change="onSubmit"
                            placeholder="Select by course and/or by course code"
                            type="text"
                            v-model="input1"
                            class="el-input form-search-bar"
                            :fetch-suggestions="querySearchAsync"
                    ></el-autocomplete>
                </el-col>
                <el-col :lg="3" :md="5" :sm="5" :xl="3" :xs="5">
                    <el-button @click="onSubmit" class="search-button-text">Search</el-button>
                </el-col>
            </el-row>

        </el-form>
    </div>

    <!-- //
    <div>
        //
        <el-form class="demo-form-inline">
            //             <el-form-item :label="searchBarLabel"></el-form-item>
            //
            <el-form-item class="form-search-bar-group">
                //
                <el-input
                // placeholder="Select by course and/or by college"
                // type="text"
                // v-model="input1"
                // class="form-search-bar"
                // ></el-input>
                //
                <el-button class="bg-purple-dark search-button-text" @click="onSubmit">Search</el-button>
                //
            </el-form-item>
            //
        </el-form>
        //
    </div>
    // </template> -->

</template>


<script>
  import {mapActions} from 'vuex';

  export default {
    name: 'SearchBar',
    data: function() {
      return {
        input1: '',
        searchBarLabel: 'Course Search', //purpose of locale
        coursesArr: [],
      };
    },
    mounted() {
      let self = this;
      self.$store.state.loadingScreen = true;
      self.fetchFiltersList().then(response => {
        self.coursesArr = response['courses'];
        self.$store.state.loadingScreen = false;
      }).catch(error => {
        self.coursesArr = error;
        self.$store.state.loadingScreen = false;
      });
    },
    methods: {
      ...mapActions(['setSearchedCourseName']),

      querySearchAsync(queryString, cb) {
        //Add a value field for each object in this array, because autocomplete only recognizes the value field and displays it in the drop-down column, so I have repackaged it.

        let programList = [];
        programList = this.coursesArr.map(function(element) {
          // if (programList.includes({value: element.title}))
          return {value: element};
        });

        let results = queryString
            ? programList.filter(this.createStateFilter(queryString))
            : programList;
        //The basic idea is to implement a filter that filters all the data that exists in your input field.
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          cb(results);
        }, 1000 * Math.random());
      },
      createStateFilter(queryString) {
        return (program) => {
          return (
              program.value.toLowerCase().indexOf(queryString.toLowerCase()) !== -1
          );
        };
      },
      // onSubmit() {
      //   console.log("Searched Called");
      //   this.$router.push("/course_filter/");
      // },
      onSubmit() {
        this.setSearchedCourseName(this.input1);
        if (this.input1 !== '')
            this.$router.push('/filter?course=' + this.input1);
      },

    },
  };
</script>

<style scoped>
    .search-button-text {
        font-family: Montserrat, serif !important;
        font-style: normal;
        font-weight: 500;
        text-align: center;
        color: #7d90d0;
    }

    .form-search-bar >>> .el-input__inner {
        font-family: Lato, serif;
        font-style: normal;
        /*font-weight: bold;*/
        color: #000000;
    }

    .course-search {
        font-family: Niramit, serif !important;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 32px;

        /* identical to box height, or 178% */
        display: flex;
        align-items: center;

        color: #000000;
    }

    .demo-form-inline {
        background: #7d90d0;
        padding-top: 1%;
        padding-bottom: 1%;
    }

    .form-search-bar-group {
        width: 100%;
    }

    .el-form-item {
        margin-bottom: 0px;
    }

    .form-search-bar {
        width: 100%;
        padding-right: 1vw;
        padding-left: 1vw;
    }

    .search-button-text {
        font-family: Montserrat, serif !important;
        font-style: normal;
        font-weight: 500;
        text-align: center;
        color: #7d90d0;
        width: 100%;
    }
</style>