<template>
    <div>
        <div style="height:100%; width:100%; background-color:rgba(0,0,0,0.8); position:fixed; top:0; left:0; z-index:999999 !important;">
            <div style="margin-left: 47.5vw;margin-top: 45vh;">
                <div class="half-circle-spinner">
                    <div class="circle circle-1"></div>
                    <div class="circle circle-2"></div>
                </div>
            </div>
            <div class="circle" style="color: white; font-weight: 900; font-size: 14px; width: 100%;margin-top: 1vh;"
                 v-if="message">
                {{message}}
            </div>
        </div>
    </div>
</template>
<script>

  export default {
    name: 'LoadingScreen',
    props: ['message'],
    data: function() {
      return {
        loadingColor: 'orange',
      };
    },
  };
</script>
<style scoped>
    .half-circle-spinner, .half-circle-spinner * {
        box-sizing: border-box;
    }

    .half-circle-spinner {
        width: 5vw;
        height: 5vw;
        border-radius: 100%;
        position: relative;
    }

    .half-circle-spinner .circle {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        border: calc(60px / 10) solid transparent;
    }

    .half-circle-spinner .circle.circle-1 {
        border-top-color: #102626;
        animation: half-circle-spinner-animation 1s infinite;
    }

    .half-circle-spinner .circle.circle-2 {
        border-bottom-color: #102626;
        animation: half-circle-spinner-animation 1s infinite alternate;
    }

    @keyframes half-circle-spinner-animation {
        0% {
            transform: rotate(0deg);

        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>
