import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    predictionsSubmitted: false,
    isAdmin: false,
    firstName: '',
    lastName: '',
    careerFitCompleted: false,
    interestFitCompleted: false,
    predictedCAOPoints: '',
    userLoggedIn: false,
    isPremiumUser: false,
    careerTestQuestions: [],
    careerTestResults: [],
    personalityTestResults: [],
    personalityTestQuestions: [],
    recommendations: false,
    recommendationsGenerated: false,
    favouriteCollege: '',
    schoolName: '',
    selectedCourseData: false,
    searchedCourseName: '',
    searchedCourses: [],
    searchedCoursePages: 1,
    searchedCollegeName: '',
    totalSearchCount: 0,
    totalLeaderboardCount: 0,
    currentPageNumber: 1,
    nextPage: '',
    previousPage: '',
    searchedSectorName: '',
    searchedProvinceName: '',
    searchedLevel: '',
    careerSectorsList: [],
    shortlistedCourses: {'8': [], '6_7': []},
    caoFormCourses: [],
    exemptIrish: false,
    exemptLanguages: false,
    studentFullName: '',
    studentEmail: false,
    selectedCollege: {'8': false, '6_7': false},
    sortType: '',
    feedbackGiven: false,
    showFeedbackDialogue: false,
    userType: '',
    showSidebar: true,
    counsellorStudentId: '',
    counsellorStudentName: '',
    schoolCounsellorAccess: true,
    privateCounsellorAccess: true,
    loadingScreen: false,
    loadingMessage: '',
    activeName: 'course_fit',
    courseLevelActiveName: '8',
    caoLevelActiveName: '8',
    userId: false,
    currentSchoolYear: '',
    schools: [],
    colleges: [],
    referralCode: '',
  },
  getters: {
    getFirstName: state => state.firstName,
    getLastName: state => state.lastName,
    getCurrentSchoolYear: state => state.currentSchoolYear,
    getSchoolCounsellorAccess: state => state.schoolCounsellorAccess,
    getPrivateCounsellorAccess: state => state.privateCounsellorAccess,
    getUserId: state => state.userId,
    getUserType: state => state.userType,
    getRecommendationsGenerated: state => state.recommendationsGenerated,
    getFeedbackGiven: state => state.feedbackGiven,
    getSortType: state => state.sortType,
    getSelectedCollege: state => state.selectedCollege,
    getStudentEmail: state => state.studentEmail,
    getStudentFullName: state => state.studentFullName,
    getPredictionsSubmitted: state => state.predictionsSubmitted,
    getIsAdmin: state => state.isAdmin,
    getExemptLanguages: state => state.exemptLanguages,
    getExemptIrish: state => state.exemptIrish,
    getCAOFormCourses: state => state.caoFormCourses,
    getShortlistedCourses: state => state.shortlistedCourses,
    getSchoolName: state => state.schoolName,
    getCareerFitCompleted: state => state.careerFitCompleted,
    getInterestFitCompleted: state => state.interestFitCompleted,
    getStoreCareerTestResults: state => state.careerTestResults,
    getStorePersonalityTestResults: state => state.personalityTestResults,
    getPredictedCAOPoints: state => state.predictedCAOPoints,
    getUserLoggedIn: state => state.userLoggedIn,
    getIsPremiumUser: state => state.isPremiumUser,
    getCareerTestQuestions: state => state.careerTestQuestions,
    getPersonalityTestQuestions: state => state.personalityTestQuestions,
    getRecommendations: state => state.recommendations,
    getFavouriteCollege: state => state.favouriteCollege,
    getSelectedCourseData: state => state.selectedCourseData,
    getSearchedCourses: state => state.searchedCourses,
    getSearchedCourseName: state => state.searchedCourseName,
    getSearchedCollegeName: state => state.searchedCollegeName,
    getSearchedSectorName: state => state.searchedSectorName,
    getSearchedProvinceName: state => state.searchedProvinceName,
    getSearchedLevel: state => state.searchedLevel,
    getCareerSectorsList: state => state.careerSectorsList,
  },
  mutations: {
    setCurrentSchoolYear: function(state, data) {
      state.currentSchoolYear = data;
    },
    setSchoolCounsellorAccess: function(state, data) {
      state.schoolCounsellorAccess = data;
    },
    setPrivateCounsellorAccess: function(state, data) {
      state.privateCounsellorAccess = data;
    },
    setUserId: function(state, data) {
      state.userId = data;
    },
    setUserType: function(state, data) {
      state.userType = data;
    },
    setRecommendationsGenerated: function(state, data) {
      state.recommendationsGenerated = data;
    },
    setFeedbackGiven: function(state, data) {
      state.feedbackGiven = data;
    },
    setSortType: function(state, data) {
      state.sortType = data;
    },
    setSelectedCollege: function(state, data) {
      state.selectedCollege = data;
    },
    setStudentFullName: function(state, data) {
      state.studentFullName = data;
    },
    setStudentEmail: function(state, data) {
      state.studentEmail = data;
    },
    setPredictionsSubmitted: function(state, data) {
      state.predictionsSubmitted = data;
    },
    setIsAdmin: function(state, data) {
      state.isAdmin = data;
    },
    setExemptLanguages: function(state, data) {
      state.exemptLanguages = data;
    },
    setExemptIrish: function(state, data) {
      state.exemptIrish = data;
    },
    setShortlistedCourses: function(state, data) {
      state.shortlistedCourses = data;
    },
    setCareerSectorsList: function(state, data) {
      state.careerSectorsList = data;
    },
    setSearchedSectorName: function(state, data) {
      state.searchedSectorName = data;
    },
    setSearchedProvinceName: function(state, data) {
      state.searchedProvinceName = data;
    },
    setSearchedLevel: function(state, data) {
      state.searchedLevel = data;
    },
    setSearchedCourseName: function(state, data) {
      state.searchedCourseName = data;
    },
    setSearchedCourses: function(state, data) {
      state.searchedCourses = data;
    },
    setSchoolName: function(state, data) {
      state.schoolName = data;
    },
    setUserLoggedIn: function() {
      this.state.userLoggedIn = true;
    },
    setPredictedCAOPoints: function(state, data) {
      state.predictedCAOPoints = data;
    },
    setUserLoggedOut: function(state) {
      state.userLoggedIn = false;
    },
    setIsPremiumUser: function(state, value) {
      state.isPremiumUser = value;
    },
    setCareerTestQuestions: function(state, data) {
      state.careerTestQuestions = data;
    },
    setCareerTestResults: function(state, data) {
      state.careerTestResults = data;
    },
    setPersonalityTestResults: function(state, data) {
      state.personalityTestResults = data;
    },
    setPersonalityTestQuestions: function(state, data) {
      state.personalityTestQuestions = data;
    },
    setCareerFitCompleted: function(state, data) {
      state.careerFitCompleted = data;
    },
    setInterestFitCompleted: function(state, data) {
      state.interestFitCompleted = data;
    },
    setRecommendations: function(state, data) {
      state.recommendations = data;
    },
    setFavouriteCollege: function(state, data) {
      state.favouriteCollege = data;
    },
    setSelectedCourseData: function(state, data) {
      state.selectedCourseData = data;
    },
    setSearchedCollegeName: function(state, data) {
      state.searchedCollegeName = data;
    },
    setCAOFormCourses: function(state, data) {
      state.caoFormCourses = data;
    },
    setNextPage: function(state, data) {
      state.nextPage = data;
    },
    setPreviousPage: function(state, data) {
      state.previousPage = data;
    },
  },
  actions: {
    setCurrentSchoolYear: function({commit}, payload) {
      commit('setCurrentSchoolYear', payload);
    },
    setSchoolCounsellorAccess: function({commit}, payload) {
      commit('setSchoolCounsellorAccess', payload);
    },
    setPrivateCounsellorAccess: function({commit}, payload) {
      commit('setPrivateCounsellorAccess', payload);
    },
    setUserId: function({commit}, payload) {
      commit('setUserId', payload);
    },
    setUserType: function({commit}, payload) {
      commit('setUserType', payload);
    },
    setRecommendationsGenerated: function({commit}, payload) {
      commit('setRecommendationsGenerated', payload);
    },
    setFeedbackGiven: function({commit}, payload) {
      commit('setFeedbackGiven', payload);
    },
    setSortType: function({commit}, payload) {
      commit('setSortType', payload);
    },
    setSelectedCollege: function({commit}, payload) {
      commit('setSelectedCollege', payload);
    },
    setStudentEmail: function({commit}, payload) {
      commit('setStudentEmail', payload);
    },
    setStudentFullName: function({commit}, payload) {
      commit('setStudentFullName', payload);
    },
    setPredictionsSubmitted: function({commit}, payload) {
      commit('setPredictionsSubmitted', payload);
    },
    setIsAdmin: function({commit}, payload) {
      commit('setIsAdmin', payload);
    },
    setExemptLanguages: function({commit}, payload) {
      commit('setExemptLanguages', payload);
    },
    setExemptIrish: function({commit}, payload) {
      commit('setExemptIrish', payload);
    },
    setCAOFormCourses: function({commit}, payload) {
      commit('setCAOFormCourses', payload);
    },
    setShortlistedCourses: function({commit}, payload) {
      commit('setShortlistedCourses', payload);
    },
    setCareerSectorsList: function({commit}, payload) {
      commit('setCareerSectorsList', payload);
    },
    setSearchedSectorName: function({commit}, payload) {
      commit('setSearchedSectorName', payload);
    },
    setSearchedProvinceName: function({commit}, payload) {
      commit('setSearchedProvinceName', payload);
    },
    setSearchedLevel: function({commit}, payload) {
      commit('setSearchedLevel', payload);
    },
    setSearchedCollegeName: function({commit}, payload) {
      commit('setSearchedCollegeName', payload);
    },
    setSearchedCourseName: function({commit}, payload) {
      commit('setSearchedCourseName', payload);
    },
    setSearchedCourses: function({commit}, payload) {
      commit('setSearchedCourses', payload);
    },
    setSchoolName: function({commit}, payload) {
      commit('setSchoolName', payload);
    },
    setUserLoggedIn: function({commit}, payload) {
      commit('setUserLoggedIn', payload);
    },
    setPredictedCAOPoints: function({commit}, payload) {
      commit('setPredictedCAOPoints', payload);
    },
    setCareerTestQuestions({commit}, payload) {
      commit('setCareerTestQuestions', payload);
    },
    setCareerTestResults({commit}, payload) {
      commit('setCareerTestResults', payload);
      commit('setCareerFitCompleted', true);
    },
    setPersonalityTestResults({commit}, payload) {
      commit('setPersonalityTestResults', payload);
      commit('setInterestFitCompleted', true);
    },
    setPersonalityTestQuestions({commit}, payload) {
      commit('setPersonalityTestQuestions', payload);
    },
    setCareerFitCompleted: function({commit}, payload) {
      commit('setCareerFitCompleted', payload);
    },
    setInterestFitCompleted: function({commit}, payload) {
      commit('setInterestFitCompleted', payload);
    },
    setRecommendations: function({commit}, payload) {
      commit('setRecommendations', payload);
    },
    setFavouriteCollege: function({commit}, payload) {
      commit('setFavouriteCollege', payload);
    },
    setSelectedCourseData: function({commit}, payload) {
      commit('setSelectedCourseData', payload);
    },
    setIsPremiumUser: function({commit}, payload) {
      commit('setIsPremiumUser', payload);
    },
    setNextPage: function({commit}, payload) {
      commit('setNextPage', '');
    },
    setPreviousPage: function({commit}, payload) {
      commit('setPreviousPage', '');
    },
    setUserLoggedOut: function({commit}) {
      commit('setSelectedCourseData', false);
      commit('setRecommendations', false);
      commit('setCareerFitCompleted', false);
      commit('setInterestFitCompleted', false);
      commit('setCareerTestResults', false);
      commit('setPersonalityTestResults', false);
      commit('setSchoolName', '');
      commit('setIsPremiumUser', false);
      commit('setFavouriteCollege', '');
      commit('setUserLoggedOut');
      commit('setPredictedCAOPoints', false);
      commit('setUserType', '');
      commit('setIsAdmin', false);
      commit('setSearchedCourses', []);
      commit('setNextPage', '');
      commit('setPreviousPage', '');
    },
  },
});
