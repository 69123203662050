<template>
    <img :src="imageSrc"
         :alt="collegeName"
         @error="onImageError"
         :class="className"
         :style="computedImgStyle" />
  </template>
  
  <script>
  export default {
    name: 'CollegeImage',
    props: {
      collegeName: {
        type: String,
        required: true
      },
      className: {
        type: String,
        default: ''
      },
      imgStyle: {
        type: Object,
        default: ''
      }
    },
    data() {
      return {
        imageStatus: {},
        imageSrc: `${this.publicPath}img/colleges/${this.collegeName}.svg`
      };
    },
    computed: {
      computedImgStyle() {
        return this.className ? {} : this.imgStyle;
      }
    },
    methods: {
      checkAllImages() {
        const svgPath = `${this.publicPath}img/colleges/${this.collegeName}.svg`;
        this.checkImage(svgPath);
      },
      checkImage(svgPath) {
        const img = new Image();
        img.onload = () => {
          this.$set(this.imageStatus, svgPath, true);
          this.imageSrc = svgPath;
        };
        img.onerror = () => {
          this.$set(this.imageStatus, svgPath, false);
          this.imageSrc = this.getImageSrc();
        };
        img.src = svgPath;
      },
      getImageSrc() {
        const svgPath = `${this.publicPath}img/colleges/${this.collegeName}.svg`;
        const pngPath = `${this.publicPath}img/colleges/${this.collegeName}.png`;
        return this.imageStatus[svgPath] ? svgPath : pngPath;
      },
      onImageError() {
        this.imageSrc = `${this.publicPath}img/colleges/${this.collegeName}.png`;
      }
    },
    mounted() {
      this.checkAllImages();
    }
  };
  </script>
  