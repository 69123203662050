<template>
    <div>
        <el-row class="loginTitle">
            <el-col>Sign in to Skillsvista student!</el-col>
        </el-row>
        <el-row>
            <el-row style=" padding: 0 1vw;">
                <el-row style="text-align: left !important;margin: 1vh 0 0;">
                    <label>Your Email</label>
                </el-row>
                <el-row>
                    <el-input placeholder="" v-model="emailId"></el-input>
                </el-row>
                <el-row style="text-align: left !important;margin: 2vh 0 0;">
                    <label>Your Password</label>
                </el-row>
                <el-row style="margin: 0;">
                    <el-input placeholder="Your Password" show-password type="password"
                              v-model="password"></el-input>
                </el-row>
                <el-row style="margin: 2vh 0;">
                    <el-col :offset="16" :span="8">
                        <div class="forgot-password" @click="sendNewPassword">Forgot Password ?</div>
                    </el-col>
                </el-row>
                <el-row style="margin: 2vh 0 0;" v-if="!fromPayment">
                    <el-col>
                        <el-button @click="performLogin()" class="login-btn">Login</el-button>
                    </el-col>
                </el-row>
                <el-row v-if="mainError !== '' && mainError !== undefined">
                    <div style="font-size: 12px; color: red; text-align: left; margin-top: 1vh;">
                        {{mainError}}
                    </div>
                </el-row>
                <el-row style="margin: 2vh 0; font-size: 12px;">
                    <el-col :offset="8" :span="8">Or Sign in Using</el-col>
                </el-row>
                <el-row style="margin: 2vh 0;">
                    <el-col :xl="{span: 3, offset: 9}" :lg="{span: 3, offset: 9}" :md="{span: 3, offset: 9}"
                            :sm="{span: 6, offset: 6}" :xs="{span: 6, offset: 6}">
                        <!--                        <el-button-->
                        <!--                                @click="authenticate('facebook', {'provider': 'facebook'})"-->
                        <!--                                class="btn-sign fb">Facebook-->
                        <!--                        </el-button>-->
                        <img :src="`${publicPath}img/facebook.svg`" alt="" style="cursor:pointer;"
                             @click="authenticate('facebook', {'provider': 'facebook'})">
                    </el-col>
                    <el-col :xl="{span: 3}" :lg="{span: 3}" :md="{span: 3}" :sm="{span: 6}" :xs="{span: 6}">
                        <!--                        <el-button-->
                        <!--                                @click="authenticate('google', {'provider': 'google-oauth2'})"-->
                        <!--                                class="btn-sign gg">-->
                        <!--                            Google-->
                        <!--                        </el-button>-->
                        <img :src="`${publicPath}img/google.svg`" alt="" style="cursor:pointer;"
                             @click="authenticate('google', {'provider': 'google-oauth2'})">
                    </el-col>
                </el-row>
            </el-row>
        </el-row>
        <!--        <span class="not-member" v-if="!fromPayment">-->
        <!--            Not a member?-->
        <!--            <span @click="showSignUp" class="signUp">Create Account</span>-->
        <!--        </span>-->
    </div>
</template>

<script>
  import LoadingScreen from './LoadingScreen';

  export default {
    name: 'LoginInformation',
    components: {LoadingScreen},
    props: ['fromPayment'],
    data: function() {
      return {
        emailId: '',
        password: '',
        mainError: '',
        loadingScreen: false,
      };
    },
    methods: {
      sendNewPassword: function() {
        let self = this;
        let url = self.getBaseUrl() + '/accounts/forgot_password/';
        if (this.emailId === '') {
          self.$message({
            title: 'Unsuccessful',
            message: 'Please enter correct email id',
            type: 'error',
            duration: 7000,
            showClose: true,
          });
          return false;
        }
        self.$store.state.loadingScreen = true;
        $.ajax({
          url: url,
          contentType: 'application/json',
          type: 'POST',
          data: JSON.stringify({email: self.emailId}),
          success: function(response) {
            self.$store.state.loadingScreen = false;
            self.$message({
              title: 'Successful',
              message: 'New password has been sent to the above email address. Kindly use that password for login.',
              type: 'success',
              duration: 7000,
              showClose: true,
            });
          },
          error: function(error) {
            self.$store.state.loadingScreen = false;
            self.$message({
              title: 'Unsuccessful',
              message: 'Something went wrong, please contact us with your issue.',
              type: 'error',
              duration: 7000,
              showClose: true,
            });
          },
        });
      },
      showSignUp: function() {
        let path = {path: '/create-account/'};
        let redirectURL = this.$route.query.redirect;
        if (redirectURL !== undefined) {
          path['query'] = {redirect: redirectURL};
        }
        this.$router.push(path);
      },
    },
  };
</script>

<style scoped>

</style>
