<template>
    <div>
        <span class="popular-college" style="color: white;">Popular Colleges</span>
        <el-row>
            <el-col
                    :key="index"
                    :lg="8"
                    :md="12"
                    :sm="24"
                    :xl="8"
                    :xs="24"
                    v-for="(college, index) in displayList">
                <el-card @click.native="cardClick(college.name)" class="college-card-course-search">
                    <el-row class="card-rows">
                        <el-col :lg="6" :md="6" :sm="6" :xl="6" :xs="6" 
                            class="college-info-card">
                              <CollegeImage :collegeName=college.name :publicPath="publicPath" :imgStyle="{ width: '100%' }"></CollegeImage>
                        </el-col>
                        <el-col :lg="18" :md="18" :sm="18" :xl="18" :xs="18" 
                                :title="college.name"
                                class="college-info-card">
                            <div class="college-name-list">
                                {{college.name}}
                            </div>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>

        <el-row>
            <el-col :offset="11" :span="6">
                <a @click="clickedSeeMoreColleges" class="more-items" style="color: white" type="primary" v-if="moreItemCounter">
                    See More
                    <i class="el-icon-arrow-down"></i>
                </a>
                <a @click="clickedSeeLessColleges" class="more-items" type="primary" v-else>
                    Show Less
                    <i class="el-icon-arrow-up"></i>
                </a>
            </el-col>
        </el-row>
    </div>
</template>

<script>
  import CollegeImage from '../CommonComponents/CollegeImage.vue';
  import $ from 'jquery';
  import {mapActions} from 'vuex';

  export default {
    name: 'CollegeList',
    props: ['level', 'publicPath'],
    components: { CollegeImage }, // Register the new component
    data: () => {
      return {
        page: {},
        moreItemCounter: true,
        displayItems: 9,
        collegesList: [],
        displayList: [],
      };
    },
    created: function() {
      //look at screen resolution and pick the display array
      window.addEventListener('resize', this.eventHandler);
      this.detectDeviceSize();
    },
    mounted: function() {
      //get data from API collegelist

      this.fetchPopularColleges();
    },
    destroyed: function() {
      window.removeEventListener('resize', this.eventHandler);
    },
    methods: {
      ...mapActions(['setSearchedCollegeName']),
      fetchPopularColleges() {
        let self = this;

        $.ajax({
          url: self.getBaseUrl() + '/keywords/college/list/',
          type: 'GET',
          // beforeSend: function(request) {
          //   request.setRequestHeader(
          //       'Authorization',
          //       'Token ' + self.getAuthToken(),
          //   );
          // },
          success: function(response) {
            self.collegesList = response;
            self.detectDeviceSize();
          },
          error: function(err) {
            console.log(err);
          },
        });
      },
      displayCollegeList: function() {
        let endPosition = this.displayItems;
        this.displayList = this.collegesList.slice(0, endPosition);
      },
      detectDeviceSize: function() {
        var w = window.innerWidth;
        if (w < 768) {
          this.displayItems = 3;
        } else if (w < 960) {
          this.displayItems = 3;
        } else if (w < 1200) {
          this.displayItems = 8;
        } else {
          this.displayItems = 9;
        }
        this.displayCollegeList();
      },
      eventHandler: function() {
        this.detectDeviceSize();
      },
      cardClick: function(collegeName) {
        this.setSearchedCollegeName(collegeName);
        this.checkCollegePageAvailable(collegeName).then(response => {
          if (response.status === 1) {
            this.$router.push('/college/' + response.sn + '/');
          } else {
            this.$router.push('/filter?college=' + collegeName);
          }
        }).catch(e => {

        });
      },
      checkCollegePageAvailable: function(collegeName) {
        return new Promise(((resolve, reject) => {
          let self = this;
          let url = self.getBaseUrl() + '/keywords/check-cp/';
          $.ajax({
            url: url,
            type: 'GET',
            data: {'college_name': collegeName},
            beforeSend: function(request) {
              if (self.getAuthToken() !== '')
                request.setRequestHeader('Authorization', 'Token ' + self.getAuthToken(),
                );
            },
            success: function(response) {
              resolve(response);
            },
            error: function() {
              reject(false);
            },
          });
        }));
      },
      clickedSeeMoreColleges: function() {
        // alert("see more");
        // i have no idea how much on each more you want to add so just doubling it.
        this.displayItems = this.displayItems + this.displayItems;
        this.displayCollegeList();
        this.isMore();
      },
      clickedSeeLessColleges: function() {
        this.displayItems = this.displayItems / 2;
        this.displayCollegeList();
        this.isMore();
      },
      isMore: function() {
        if (this.displayItems >= this.collegesList.length) {
          this.moreItemCounter = false;
          return;
        }
        this.moreItemCounter = true;

      },
    },
  };
</script>

<style>
    /*.college-card-course-search > .el-card__body {*/
    /*    height: 100%;*/
    /*}*/
</style>
<style scoped>

    .college-info-card {
      display: flex; 
      align-items: center; 
      height: 100%;
      padding: 5px;
    }
    .college-card-course-search >>> .el-card__body {
        padding: 0 !important;
    }

    .popular-college {
        font-style: normal;
        font-weight: 400;
        /* identical to box height, or 178% */
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
    }

    .college-card-course-search {
        background: #ffffff;
        box-shadow: 0px 4px 7px rgba(192, 203, 211, 0.15);
        border-radius: 4px;
        /*margin: 16px;*/
        height: 12vh;
    }

    .college-card-course-search:hover {
        cursor: pointer;
    }


    .more-items {
        text-align: center;
        font-family: Lato, serif;
        font-style: normal;
        font-weight: 300;
        cursor: pointer;
        /* identical to box height, or 267% */
        display: flex;
        align-items: center;
        color: #000000;
    }


</style>
