<template>
    <div>
        <el-row>
            <img :src="`${publicPath}${partnerData.cover}`" alt="cover" class="spotlight-cover-img">
            <img :src="`${publicPath}${partnerData.logo}`" alt="logo" class="spotlight-logo-img">
        </el-row>
        <el-row class="spotlight-heading">
            {{partnerData.name}}
        </el-row>
        <el-row class="spotlight-tagline">
            {{partnerData.tagline}}
        </el-row>
        <el-row class="spotlight-intro">
            {{partnerData.intro}}
        </el-row>
        <el-row class="button-row">
            <button class="unlock-button unlock-button--text"
                    @click="$router.push(`/${partnerData.partner_type}/${partnerData.short_name}/`)">
                Learn More
            </button>
        </el-row>

    </div>
</template>

<script>
  export default {
    name: 'SpotLight',
    props: ['partnerName'],
    data: function() {
      return {
        partnerData: {
          name: 'Musgrave',
          partner_type: 'company',
          short_name: 'musgrave',
          tagline: 'The best education',
          intro: 'leading food retail, wholesale and foodservice company',
          cover: 'img/company/cover/musgrave.png',
          logo: 'img/company/logo/musgrave.jpg',
        },
      };
    },
  };
</script>

<style scoped>
    .spotlight-cover-img {
        width: 100%;
        height: 20vh;
    }

    .spotlight-logo-img {
        position: absolute;
        height: 10vh;
        width: 40%;
        margin-top: 0;
        left: 30%;
        top: 25%;
        background: white;
        padding: 15px 20px;
    }

    .button-row {
        padding: 16px;
    }
</style>
