<template>
    <el-card
            class="box-card"
            style="
                padding: 16px 0;
                height: auto !important;
                box-shadow: 0 4px 7px 1px rgba(192, 203, 211, 0.15);
                border-radius: 10px;">
        <el-row>
            <el-col :span="6">
                <div class="image-box">
                    <img :src="`${publicPath}img/homepage/second-block.svg`" alt="no image" style="height: 18vh;"/>
                </div>
            </el-col>
            <el-col :span="18" class="main-col">
                <el-row>
                    <div class="block1 heading1">
                        Your Skillsvista Fit!
                    </div>
                    <div style="position: absolute; right: 0; top: 0;" v-if="!getIsPremiumUser">
                        <lock-image-png></lock-image-png>
                    </div>
                </el-row>
                <el-row>
                    <el-col :span="20" class="block1 description1">
                        We match you with your best-fit college courses through scientific analysis of your Academics,
                        Personality and Interests.
                    </el-col>
                </el-row>
                <el-row style="margin-top: 2vh;">
                    <el-col :span="5">
                        <button @click="$router.push({path: '/login/', query: { redirect: '/skillsvista_fit/' }})"
                                class="black-primary-button takeQuizButton"
                                style="width: 100%"
                                v-if="!getIsPremiumUser">
                            Sign Up
                        </button>
                        <!--                        <button @click="$router.push({path: '/login/', query: { redirect: '/skillsvista_fit/' }})"-->
                        <!--                                class="unlock-button unlock-button&#45;&#45;text"-->
                        <!--                                v-if="!getIsPremiumUser">-->
                        <!--                            Sign Up-->
                        <!--                        </button>-->
                        <button @click="$router.push('/skillsvista_fit/')"
                                class="course-explore-button"
                                v-else-if="getIsPremiumUser">
                            <el-col :span="20">
                                Explore
                            </el-col>
                            <el-col :span="4">
                                <img :src="`${publicPath}img/homepage/explore-arrow.svg`"
                                     alt="no image"
                                     style="width: 1vw; margin: auto;"/>
                            </el-col>
                        </button>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </el-card>
</template>

<script>
  import LockImagePng from '../../CommonComponents/LockImagePng';

  export default {
    name: 'WebSecondBlock',
    components: {LockImagePng},
    mounted() {
    },
    data: function() {
      return {};
    },
  };
</script>
<style scoped>
    .main-col {
        padding: 0 10px;
    }

    .block1 {
        word-break: break-word;
        font-family: Lato, serif;
        font-style: normal;
        text-align: left;

    }

    .heading1 {
        font-weight: 300 !important;
        /*line-height: 3vw !important;*/
        font-size: 1.5vw !important;
        margin-top: 1vh;
        margin-bottom: 1vh;
        color: #000000;
        font-family: Niramit, serif !important;
        font-style: normal;
    }

    .description1 {
        font-weight: 300;
        font-size: 1vw;
        color: #000000;
        display: flex;
        align-items: center;
    }

    .disabled {
        cursor: not-allowed;
        background: #DBDBDB;
        /* #C4C4C4 - Mid Grey 3 */

        border: 1px solid #C4C4C4;
        box-sizing: border-box;
        border-radius: 5px;
        color: #FFFFFF;
    }
</style>
