<template>
    <el-card
            class="box-card"
            style="padding: 1vh 0; height: 20vh !important;
                    box-shadow: 0 4px 7px 1px rgba(192, 203, 211, 0.15); border-radius: 10px;">
        <el-row>
            <el-col :span="9">
                <div class="image-box" style="height: 100%; padding: 10px">
                    <img :src="`${publicPath}img/homepage/${getImageMap}.svg`"
                         style="max-width: 100%; max-height: 100%; vertical-align: middle;" alt=""/>
                </div>
            </el-col>
            <el-col :span="15" class="main-col">
                <el-row class="block2">
                    <h3 class="heading2">
                        {{title}}
                    </h3>
                </el-row>
                <el-row class="block2">
                    <el-col class="description2">
                        {{descriptionMap[title]}}
                    </el-col>
                </el-row>
                <el-row style="margin-top: 1.5vh">
                    <el-col :span="12">
                        <button @click="$emit('showGuidancePopup')" class="unlock-button unlock-button--text"
                                v-if="title === 'Are you a Guidance Counsellor?'">
                            Register
                        </button>
                        <button @click="$router.push('/login/')"
                                class="unlock-button unlock-button--text"
                                v-else-if="!getUserLoggedIn && title !== 'Course Search'">
                            Sign up
                        </button>

                        <button @click="$router.push(getRoutesMap())"
                                class="course-explore-button"
                                v-else-if="!getUserLoggedIn && title === 'Course Search'">
                            <span>
                                Explore
                            </span>
                            <span style="margin-left: 5px;">
                                <img :src="`${publicPath}img/homepage/explore-arrow.svg`"
                                     alt="no image"
                                     style="width: 1vw; margin: auto;"/>
                            </span>
                        </button>
                        <button @click="$router.push(getRoutesMap())"
                                class="course-explore-button"
                                v-else-if="getUserLoggedIn">
                            <span>
                                Explore
                            </span>
                            <span>
                                <img :src="`${publicPath}img/homepage/explore-arrow.svg`"
                                     alt="no image"
                                     style="width: 1vw; margin: auto;"/>
                            </span>
                        </button>

                        <!--                        <button v-else-if="!getIsPremiumUser" class="unlock-button">-->
                        <!--                            Unlock-->
                        <!--                        </button>-->

                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </el-card>
</template>

<script>

  export default {
    props: ['title', 'status'],
    name: 'WebSmallBlocks',
    mounted() {
      this.userLoggedIn = this.$store.getters.getUserLoggedIn;
      this.isPremiumUser = this.$store.getters.getIsPremiumUser;
    },
    data: function() {
      return {

        imageMap: {
          'Course Search': 'small-block-1',
          'Points Predict': 'small-block-2',
          'CAO Form Explore': 'small-block-3',
          'Are you a Guidance Counsellor?': 'small-block-4',
        },
        descriptionMap: {
          'Course Search': 'Research is the key to an informed decision. ',
          'Points Predict': 'Be sure of what exact points you are predicting.',
          'CAO Form Explore': 'Shortlist your favourite courses.',
          'Are you a Guidance Counsellor?': 'Register a school Guidance Counsellor account.',
        },
        routesMap: {
          'Course Search': '/course/search/',
          'Points Predict': '/points_predict/',
          'CAO Form Explore': '/cao_form/',
          'Are you a Guidance Counsellor?': '/get_guidance/',
        },
        userLoggedIn: false,
        isPremiumUser: false,
      };
    },
    computed: {
      getImageMap: function() {
        return this.imageMap[this.title];
      },
    },
    methods: {
      getRoutesMap: function() {
        return this.routesMap[this.title];
      },
    },
  };
</script>

<style scoped>
    .main-col {
        padding: 0 1vw;
    }

    .button-position {
        width: 100%;
        padding: 0.8vh;
        background: #FFFFFF;
        border: 1px solid #C4C4C4;
        box-sizing: border-box;
        border-radius: 5px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 0.95vw;
        line-height: 0.9vw;
        align-items: center;
        text-align: center;
        color: #333333;
    }

    button[disabled] {
        cursor: not-allowed;
        background: #DBDBDB;
        /* #C4C4C4 - Mid Grey 3 */

        border: 1px solid #C4C4C4;
        box-sizing: border-box;
        border-radius: 5px;
        color: #FFFFFF;
    }
</style>
