import { render, staticRenderFns } from "./career_fit.vue?vue&type=template&id=b85d0c58&scoped=true&"
import script from "./career_fit.vue?vue&type=script&lang=js&"
export * from "./career_fit.vue?vue&type=script&lang=js&"
import style0 from "./career_fit.vue?vue&type=style&index=0&id=b85d0c58&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b85d0c58",
  null
  
)

export default component.exports