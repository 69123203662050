<template>
    <div class="mobile-control" >
        <span class="study-guide-title" style="color: white;">{{page.guideTitle}}</span>
        <span class="subject-info" style="color: white;">{{page.subjectInfo}}</span>
        <el-collapse v-model="activeName" accordion @change="handleChange" style="width: 100%;"
                     :style="!isMobile() ? {padding: '16px'} : {padding: '16px 0'}">
            <el-collapse-item
                    v-for="(data, index) in dataList"
                    :key="index"
                    v-bind:title="data.name"
                    v-bind:name="index"
                    class="collapse-tile">
                <div v-for="(el, elIndex) in data.subsector" :key="elIndex" @click="handleClick(getFormattedSector(el))"
                     class="accordion-content">
                    {{el}}
                </div>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
  import {mapActions} from 'vuex';

  export default {
    name: 'SuggestionList',
    mounted() {
      this.fetchCareerList();
    },
    methods: {
      ...mapActions(['setCareerSectorsList']),
      fetchCareerList: function() {
        let self = this;
        let url = self.getBaseUrl() + '/keywords/sectors/list/';
        $.ajax({
          url: url,
          type: 'GET',
          success: function(response) {
            self.setCareerSectorsList(response);
            self.dataList = response;
          },
        });
      },
      getFormattedSector: function(el) {
        return el.replace(' & ', ' and ');
      },
      handleClick: function(sectorName) {
        this.setSearchedSectorName(sectorName);
        this.$router.push('/sector?name=' + sectorName);
        // this.$router.push('/filter?sector=' + sectorName);
      },
      handleChange: function(el) {
      },
    },
    data: function() {
      return {
        page: {
          guideTitle: 'Not Sure What to study ?',
          subjectInfo:
              'Browse our subject guides below for the perfect introduction to a subject at degree level',
        },
        activeName: '',
        dataList: [],
      };
    },
  };
</script>

<style scoped>
    @media only screen and (max-width: 768px) {
        .mobile-control {
            margin-right: 1%;
            margin-left: 1%;
        }
    }

    .mobile-control {
        font-family: Lato, serif;
    }


    .el-collapse-item >>> .el-collapse-item__content {
        text-align: left;
        background-color: #E5E5E5;
    }

    .el-collapse-item >>> .el-collapse-item__header.is-active {
        background: #3a3f5c;
        border: 1px solid #e6e6e6;
        box-sizing: border-box;
        font-family: Lato, serif;
        font-style: normal;
        font-weight: 600;

        color: #ffffff;
    }

    .study-guide-title {
        font-family: Lato, serif;
        font-style: normal;
        font-weight: 400;
        display: flex;
        align-items: center;
        text-align: left;
        color: #000000;
    }

    .subject-info {
        font-family: Lato, serif;
        font-style: normal;
        font-weight: 300;
        display: flex;
        align-items: center;
        text-align: left;
        color: #000000;
    }

    .collapse-tile {
        background: #ffffff;
        border: 1px solid #f0f1f9;
        box-sizing: border-box;
        border-radius: 5px 5px 0px 0px;
        font-family: Lato, serif;
        font-style: normal;
        font-weight: normal;
        color: #000000;
    }
</style>