<template>
    <el-card class="subject-comp">
        <el-row style="display: flex; align-items: center;">
            <el-col :span="20" class="courseTopicTitle">Academic Fit</el-col>
            <el-col :span="4" v-if="courseFitPercent !=='NA' && courseFitPercent !== '' && courseFitPercent"
                    class="test-labels-1">
                <div style="text-align: center; width: 100%;">{{courseFitPercent}}%</div>
            </el-col>
        </el-row>

        <div class="academicTestContent">Your top subjects matching successful degree graduates</div>
        <el-row v-if="courseDetails.status === 2"
                v-for="(percent, subjectName) in courseDetails.subjects"
                :key="subjectName" style="margin-top: 1vh;">
            <el-col :span="2" class="subject-name">
                <i class="el-icon-arrow-right"></i>
            </el-col>
            <el-col :span="8" class="subject-name">{{subjectName}}</el-col>
            <el-col :span=6 :offset="4" class="subject-name">
                <span>{{percent}}%</span> Fit
            </el-col>
        </el-row>

        <div v-else-if="courseDetails.status === 1">
            <el-row>
                <button class="unlock-button unlock-button--text"
                        v-if="!getIsPremiumUser"
                        @click="$router.push({path: '/payment/', query: { redirect: $route.fullPath }})">
                    Unlock
                </button>
                <button v-else class="unlock-button unlock-button--text" @click="$router.push({path: '/skillsvista_fit/'})">
                    Perform all tests
                </button>
            </el-row>
        </div>
        <div v-else>
            <el-row>
                <button class="unlock-button unlock-button--text"
                        @click="getUserLoggedIn ? $router.push('/points_predict/'):$router.push({path: '/login/', query: { redirect: $route.fullPath }})">
                    Login
                </button>
            </el-row>
        </div>
    </el-card>
</template>


<script>

  export default {
    components: {},
    props: ['courseDetails', 'courseFitPercent'],
    name: 'SubjectCompatibility',
    watch: {},
    data: function() {
      return {
        status: 0,
        subjects: [],
      };
    },
  };
</script>

<style scoped>
    .subject-comp >>> .el-card__body {
        padding: 0 !important;
    }

    .subject-comp {
        margin: 0 1vw 3vh;
        box-shadow: 0 0 0 0;
        background: #ffffff;
        border-radius: 4px;
        padding: 16px;
    }
</style>