<template>
    <div>
        <el-row :gutter="50">
            <el-col :key="cmp.index" :lg="8" :md="8" :sm="24" :xl="8" :xs="24" v-for="(cmp, index) in componentArr">
                <transition v-if="!cmp.hover" name="el-zoom-in-center">
                    <el-card
                            @click.native="setOthersToHide(cmp)"
                            class="card-style"
                            v-bind:style="{background: cmp.color}">
                        <el-row v-if="!isMobile()">
                            <el-col :span="24">
                                <img :src="`${publicPath}img/personalities/svg/${cmp.name}-1.svg`"
                                     style="height: 12vh; padding: 1vh 0;" alt="">
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24" class="cardTitleInterest">
                                <span>{{cmp.title}}</span>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24" class="bottom-card-el">Code <strong>{{cmp.code}}</strong></el-col>
                        </el-row>
                        <hr :style="{borderTop: `1px solid ${borderColorPalette[index]}`}" style="margin: 1vh 0;">
                        <div>
                            <img :src="`${publicPath}img/interest-back.png`" alt="NA">
                        </div>
                    </el-card>
                </transition>
                <transition v-else name="el-zoom-in-center">
                    <el-card
                            @click.native="cmp.hover = false"
                            @mouseover.native="setOthersToHide(cmp)"
                            class="card-style-open">
                        <div class="interestDesc">
                            {{cmp.content}}
                        </div>
                        <hr :style="{borderTop: `1px solid ${borderColorPalette[index]}`}" style="margin: 1vh 0;">
                        <div style="position:relative; bottom: 0;">
                            <img :src="`${publicPath}img/interest-back.png`" alt="NA">
                        </div>
                    </el-card>
                </transition>

            </el-col>
        </el-row>
    </div>
</template>


<script>
  export default {
    name: 'PersonalityTypeComponentDesktop',
    methods: {
      setOthersToHide: function(cmp) {
        cmp.hover = true;
        let self = this;
        self.componentArr.map(function(element) {
          if (element.name !== cmp.name) {
            element.hover = false;
          }
        });
      },
    },
    data: function() {
      return {
        borderColorPalette: [
          '#E26D5D',
          '#3A818D',
          '#4BA8B6',
          '#C8613E',
          '#B43A6C',
          '#504178',
        ],
        componentArr: [
          {
            hover: false,
            index: 0,
            name: 'organiser',
            title: 'ORGANISER',
            code: 'O',
            color: '#EE7868',
            content: 'Organisers people generally gravitate towards facts. Working with people and abtract ideas is less apealing than data and details. They are happy to double check facts and figures and are comfortable with a structred routine. They tend not to break the rules very often.',
            url:
                'https://www.freepik.com/free-vector/two-dices-white-background_1195889.htm#page=1&query=random%20shapes&position=4',
          },
          {
            hover: false,
            index: 1,
            name: 'artistic',
            title: 'ARTISTIC',
            code: 'A',
            color: '#428B98',
            content: 'Those in the Artistic category like to express themselves in whatever form works best for them. They enjoy projects that do not necessarily follow a strict set of rules and involve designs, forms and patterns. In school these people enjoy the likes of music or art.',
            url:
                'https://www.freepik.com/free-vector/two-dices-white-background_1195889.htm#page=1&query=random%20shapes&position=4',
          },
          {
            hover: false,
            index: 2,
            name: 'enterprising',
            title: 'ENTERPRISING',
            code: 'E',
            color: '#53B0BE',
            content: 'Those in the enterprising tend to take control of group projects and lead the group. They tend to be a decision maker and sometimes take risks. These people graviate towards business and the start-up ecosystem.',
            url:
                'https://www.freepik.com/free-vector/two-dices-white-background_1195889.htm#page=1&query=random%20shapes&position=4',
          },
          {
            hover: false,
            index: 3,
            name: 'thinker',
            title: 'THINKER',
            code: 'T',
            color: '#D46A46',
            content: 'Thinkers enjoy solving problems with their minds and tend to think their way out of something. In school this population like the sciences, mathematics and any other subject that requires some form of technical analysis.',
            url:
                'https://www.freepik.com/free-vector/two-dices-white-background_1195889.htm#page=1&query=random%20shapes&position=4',
          },
          {
            hover: false,
            index: 4,
            name: 'practical',
            title: 'PRACTICAL',
            code: 'P',
            color: '#C04075',
            content: `Practicalists enjoy working on real life problems that require some hands on work to find a solution. This generally involves working with materials and machinery, so some subjects like Construction, Engineering & DCG might appeal to this person. Many realist jobs require you to work outside.`,
            url:
                'https://www.freepik.com/free-vector/two-dices-white-background_1195889.htm#page=1&query=random%20shapes&position=4',
          },
          {
            hover: false,
            index: 5,
            name: 'helper',
            title: 'HELPER',
            code: 'H',
            color: '#5C4C87',
            content: `People, people, people. Those in the Helper category often want to help others. Be it teaching, assisting or treating people, the are happy with providing a service to help others. `,
            url:
                'https://www.freepik.com/free-vector/two-dices-white-background_1195889.htm#page=1&query=random%20shapes&position=4',
          },
        ],
      };
    },
  };
</script>

<style scoped>

    .card-style {
        border: 1px solid #d3d3d3;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 2vh 2vw;
        margin: 2%;
        cursor: pointer;
    }

    .interestDesc {
        height: 23vh;
        padding: 3.5vh 3vw;
        text-align: justify;
        font-family: Lato, serif;
        font-style: normal;
        font-weight: normal;
        color: #5c4c87;
    }

    .card-style-open {
        border: 1px solid #d3d3d3;
        box-sizing: border-box;
        border-radius: 10px;
        margin: 2%;
        cursor: pointer;
    }

    .bottom-card-el {
        font-family: Lato, serif;
        font-style: normal;
        font-weight: 500;
        /*padding-top: 2vh;*/
        /* identical to box height, or 143% */
        text-align: center;
        color: rgba(255, 255, 255, 0.7);
    }

    .cardTitleInterest {
        margin-bottom: 1%;
        font-family: Niramit, serif !important;
        font-style: normal;
        font-weight: 600;
        text-align: center;
        /*padding-top: 2vh;*/
        color: #ffffff;
    }
</style>