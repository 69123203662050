
<template>
    <el-row>
        <el-dialog
                class="feedbackDialog"
                :append-to-body="true"
                :before-close="handleClose"
                :show-close="true"
                :visible.sync="dialogVisible"
                :width="isMobile() ? '90%':'50%'">
            <div slot="title" class="dialog-title">
                Feedback
            </div>
            <el-row style="margin-top: 1.5vh;">
                <el-col :span="24">
                    <div class="feedbackLabel">How satisfied are you with your overall Skilsvista Fit results?</div>
                </el-col>
            </el-row>
            <el-row>
                <el-row>
                    <smiley-ratings @setRating="setRating"></smiley-ratings>
                </el-row>
                <!--                <el-col :span="24">-->
                <!--                    <star-rating :show-rating="false" :max-rating="10" class="feedbackLabel" :star-size="40"-->
                <!--                                 :increment="1" v-model="form.satisfied_recommendation"></star-rating>-->
                <!--                </el-col>-->
            </el-row>
            <el-row style="margin-top: 1.5vh;">
                <el-col :span="24">
                    <div class="feedbackLabel">How likely are you to recommend Skillsvista to a friend on a scale of 1-10</div>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <!--                    <star-rating :padding="20" :max-rating="5" :show-rating="false" class="feedbackLabel" :star-size="45"-->
                    <!--                                 :increment="1" v-model="form.recommend_friend"></star-rating>-->
                    <el-radio-group v-model="form.recommend_friend" class="interestedAreaClass">
                        <el-radio-button :title="getTitle(index)" size="mini"
                                         v-for="index in 10" :label="index" :key="index" border>
                                {{index}}
                        </el-radio-button>
                    </el-radio-group>
                    <!--                    <el-radio v-model="form.recommend_friend" label="Yes" border>Yes</el-radio>-->
                    <!--                    <el-radio v-model="form.recommend_friend" label="No" border>No</el-radio>-->
                </el-col>
            </el-row>

            <el-row style="margin-top: 1.5vh;">
                <el-col :span="24">
                    <div class="feedbackLabel">Were your results what you expected?</div>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-radio v-model="form.expected_results" label="Yes">Yes</el-radio>
                    <el-radio v-model="form.expected_results" label="No">No</el-radio>
                </el-col>
            </el-row>
            <el-row style="margin-top: 1.5vh;">
                <el-col>
                    <div class="feedbackLabel">Please give reasons for your answer.</div>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-input type="textarea" maxlength="1000" v-model="form.expected_results_reasons"></el-input>
                </el-col>
            </el-row>
            <el-row style="margin-top: 1.5vh;">
                <el-col :span="24">
                    <div class="feedbackLabel">Is there any area that you're interested in that didn't appear in your
                        results?
                    </div>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-radio v-model="form.interested_area" label="Yes">Yes</el-radio>
                    <el-radio v-model="form.interested_area" label="No">No</el-radio>
                </el-col>
            </el-row>
            <el-row style="margin-top: 1.5vh;">
                <el-col>
                    <div class="feedbackLabel">If yes, please specify.</div>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-input type="textarea" maxlength="1000" v-model="form.interested_area_explanation"></el-input>
                </el-col>
            </el-row>

            <el-row style="margin-top: 1.5vh;">
                <el-col :offset="10">
                    <el-button style="background-color: #17484C; border: 1px solid #17484C;" type="primary" @click="onSubmit">Submit</el-button>
                </el-col>
            </el-row>
        </el-dialog>
    </el-row>
</template>

<script>
  import StarRating from 'vue-star-rating';
  import $ from 'jquery';
  import {mapActions} from 'vuex';
  import SmileyRatings from './SmileyRatings';

  export default {
    props: ['status'],
    name: 'FeedbackForm',
    components: {
      SmileyRatings,
      StarRating,
    },
    data: function() {
      return {
        dialogVisible: false,
        form: {
          recommend_friend: 0,
          satisfied_recommendation: 4,
          expected_results: '',
          expected_results_reasons: '',
          interested_area: '',
          interested_area_explanation: '',
        },
      };
    },
    mounted() {
      this.dialogVisible = this.status;
    },
    ...mapActions(['setFeedbackGiven']),
    methods: {
      getTitle: function(index) {
        if (index === 1) {
          return 'Not Likely';
        } else if (index === 10) {
          return 'Very Likely';
        }
      },
      setRating: function(value) {
        this.form.satisfied_recommendation = value;
      },
      onSubmit: function() {
        let self = this;
        let url = self.getBaseUrl() + '/accounts/feedback/save/';
        $.ajax({
          beforeSend: function(request) {
            request.setRequestHeader('Authorization',
                'Token ' + self.getAuthToken());
          },
          url: url,
          contentType: 'application/json',
          type: 'POST',
          data: JSON.stringify(self.form),
          success: function(response) {
            self.$message({
              title: 'Successful',
              message: 'Thank you for the feedback.',
              type: 'success',
              duration: 3000,
              showClose: true,
            });
            self.setFeedbackGiven(true);
            self.handleClose();
          },
          error: function(error) {
            error;
          },
        });
      },
      handleClose(done) {
        let self = this;
        self.$emit('closeFeedback');
      },
    },
  };
</script>

<style scoped>
    .feedbackDialog >>> .el-col-8 {
        text-align: left !important;
        vertical-align: middle;
    }

    .feedbackLabel {
        padding: 15px 0;
        font-weight: 600;
        height: 40px;
    }

    .feedbackDialog >>> .el-dialog__header {
        padding-left: 3vw !important;
    }

    .feedbackDialog >>> .el-dialog__body {
        padding: 1vh 3vw 3vh !important;
    }

</style>