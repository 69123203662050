<template>
    <div :style="isMobile() ? {height: '100vh', 'overflow-y': 'auto', 'overflow-x': 'hidden'}:{}">
        <el-row class="login-nav-control">
            <img @click="$router.push('/')"
                 :src="`${publicPath}img/sv-student-nobg.png`"
                 class="img"
                 style="height: 115%; padding: 0.5vh 0.5vw; cursor:pointer;" alt=""/>
        </el-row>
        <el-row class="content-page">
            <el-row :style="isMobile() ? {padding: '10px 6vw', 'margin-bottom': '10vh'}:{'margin': '20px 0', padding: '0 6vw'}">
                <el-col :sm="24" :xs="24" :md="{span: 12, offset: 1}" :lg="{span: 12, offset: 1}"
                        :xl="{span: 12, offset: 1}" v-if="!isMobile()" class="login-col-class">
                    <div class="dark-content">
                        <img @click="$router.push('/')"
                             :src="`${publicPath}img/Login.jpg`"
                             class="img"
                             style="width: 70%; height: 60vh;" alt="no image"/>
                    </div>
                </el-col>
                <el-col :xl="10" :lg="10" :md="isMobile() ? 24:8" :sm="24" :xs="24" class="login-col-class">
                    <el-row style="margin: 0 auto;"
                            class="form"
                            :style="!isMobile() ? {width: '100%', padding: '16px'}:{width: '95%', padding: '1vh 2vw'}">
                        <el-tabs id="loginTabs" type="border-card" v-model="selectedLoginTab">
                            <el-tab-pane label="Login" name="Login">
                                <login-information :fromPayment="false"></login-information>
                            </el-tab-pane>
                            <el-tab-pane label="Register" name="Register">
                                <sign-up-information :fromPayment="false"></sign-up-information>
                            </el-tab-pane>
                        </el-tabs>
                    </el-row>
                </el-col>
            </el-row>
        </el-row>
    </div>
</template>

<script>

  import LoginInformation from '../../main_components/LoginInformation';
  import SignUpInformation from '../../main_components/SignUpInformation';

  export default {
    name: 'DesktopLoginPage',
    components: {SignUpInformation, LoginInformation},
    mounted() {
      this.sendAnalytics();
      const queryString = this.$route.query;
      if (queryString['code'] !== undefined) {
        this.selectedLoginTab = 'Register';
      }
    },
    data: function() {
      return {
        loadingScreen: false,
        emailId: '',
        password: '',
        page: {createAcc: '  Create an Account'},
        mainError: '',
        selectedLoginTab: 'Login',
      };
    },

  };
</script>

<style scoped>

    .title {
        padding-top: 5vw;
        font-family: Montserrat, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 44px;
        line-height: 54px;
        text-align: center;
        color: rgba(0, 0, 0, 0.7);
    }

    .not-member {
        font-family: Lato, serif;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 32px;
        text-align: center;
        color: #000000;
    }

    .signUp {
        font-weight: 400;
        cursor: pointer;
    }

    .form-input-log >>> .el-input__inner::placeholder {
        font-family: Lato, serif;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 32px;
        display: flex;
        align-items: center;
        align-content: center;
        /* identical to box height, or 229% */

        color: #000000;
    }

    .form-input-log2 >>> .el-input__inner::placeholder {
        font-family: Lato, serif;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 32px;
        display: flex;
        align-items: center;
        align-content: center;
        /* identical to box height, or 229% */

        color: #000000;
    }

    .form {
        background: #ffffff;
        box-shadow: 0 7px 7px 7px rgba(192, 203, 211, 0.55);
        border-radius: 10px 0;
    }

    .dark-content {
        /*background: #3a3f5c;*/
        background: transparent;
        border-radius: 10px 0 0 10px;
        height: 70vh;
        width: 100%;
        padding: 2vw;
    }

    .img {
        margin-top: 0.5vh;
    }

    .full-width {
        height: 100vh !important;
        width: 100vw !important;
    }

    .login-nav-control {
        width: 100%;
        background: #ffffff;
        height: 10vh;
        z-index: 999;
    }

    .sign-up-div {
        padding-top: 1vh;
    }

    .btn-div {
        padding-top: 1vh;
        padding-bottom: 2vh;
    }
</style>
