import Vue from 'vue';
import App from './App.vue';
import {store} from './store';
import routes from './routes';
import ElementUI from 'element-ui';
import VueAxios from 'vue-axios';
import VueAuthenticate from 'vue-authenticate';
import VueRouter from 'vue-router';
import 'element-ui/lib/theme-chalk/index.css';
import mixins from './mixins';
import DataTables from 'vue-data-tables';
import VueAwesomeSwiper from 'vue-awesome-swiper';
// If you use Swiper 6.0.0 or higher
import 'swiper/swiper-bundle.css';
// set language to EN
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import axios from 'axios';
import VueYouTubeEmbed from 'vue-youtube-embed';
import VueFacebookPixel from 'vue-analytics-facebook-pixel';
// import VueAnalytics from 'vue-analytics';
// import ECharts modules manually to reduce bundle size
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/tooltip';
import VueGallery from 'vue-gallery';
import VueClipboard from 'vue-clipboard2';
import $ from 'jquery';
import jQuery from 'jquery';
window.jQuery = window.$ = jQuery;


Vue.use(ElementUI);
Vue.use(VueRouter);
Vue.component('VGallery', VueGallery);
Vue.use(VueClipboard);
locale.use(lang);
Vue.use(VueFacebookPixel);
Vue.use(DataTables);
Vue.use(VueAxios, axios);
Vue.use(VueAwesomeSwiper /* { default options with global component } */);
Vue.use(require('vue-cookies'));

Vue.use(VueYouTubeEmbed);
Vue.use(VueYouTubeEmbed, {global: false});
if (process.env.NODE_ENV === 'production')
    Vue.use(VueAuthenticate, require('./frontend-prod-config.json'));
else
    Vue.use(VueAuthenticate, require('./frontend-local-config.json'));

Vue.use(VueAuthenticate, require('./frontend-local-config.json'));
var vueAuth = VueAuthenticate.factory(Vue.prototype.$http, {
  baseUrl: 'http://localhost:8000',
});

Vue.analytics.fbq.init('3371923566262282', {
  em: 'shrenik@yooni.ie',
});
const router = new VueRouter({
  routes: routes.routes,
  // mode: "history",
});

// Vue.config.performance = true;

//
// Vue.use(VueAnalytics, {
//   id: 'UA-134862200-1',
//   linkers: ['localhost:8080', 'dev.yooni.ie'],
//   router: router,
//   autoTracking: {
//     page: true,
//     exception: true,
//     screenview: true,
//     prependBase: false,
//     transformQueryString: false,
//     pageviewTemplate(route) {
//       return {
//         page: route.path,
//         title: document.title,
//         location: window.location.href,
//       };
//     },
//   },
// });

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  components: {App},
  router: router,
  store: store,
  mixins: [mixins],
  vueAuth: vueAuth,
}).$mount('#app');
