<template>
    <div style="margin-bottom: 2vh;">
        <el-row :gutter="24" class="row-stack-card">
            <el-col :key="index" :xl="6" :lg="6" :md="8" :sm="24" :xs="24" style="margin-top: 2vh;"
                    v-for="(course, index) in displayList">
                <el-card @click.native="courseCardClicked(course)" class="course-card-filter">
                    <div class="header-card" slot="header">
                        <div class="step-class-course">
                            <svg fill="none" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="25" cy="25" fill="#59A3CC" r="23"/>
                                <text dominant-baseline="middle" fill="white" text-anchor="middle" x="50%" y="55%">
                                    {{course.level}}
                                </text>
                            </svg>
                        </div>
                        <span v-if="getUserLoggedIn">
                            <el-button @click.stop="shortlistCourse(course, 'remove')" class="shortlist"
                                       v-if="courseShortlisted(course.code, course.level)">
                                Shortlist<i class="el-icon-star-on" style="color: gold !important;"></i>
                            </el-button>
                            <!--                            <i v-if="courseShortlisted(course.code)" class="el-icon-collection-tag shortlist"-->
                            <!--                               @click.stop="shortlistCourse(course, 'remove')"-->
                            <!--                               style="color: gold !important;"></i>-->
                            <!--                            <i @click.stop="shortlistCourse(course, 'add')"-->
                            <!--                               class="el-icon-collection-tag shortlist" v-else size="medium"></i>-->
                            <el-button @click.stop="shortlistCourse(course, 'add')" class="shortlist" v-else>
                                Shortlist<i class="el-icon-star-off"></i>
                            </el-button>
                        </span>
                        <!-- src="http://www.placehold.it/200x200" -->
                        <CollegeImage :collegeName="college.name" :publicPath="publicPath" :imgStyle="{ height: '100%' }"></CollegeImage>
                    </div>
                    <el-row style="height: 2.5vh;"
                            :style="isMobile() ? {}:{}">
                        <el-col class="university-row">
                            <i class="el-icon-location-outline"></i>
                            {{course.college_campus}}
                        </el-col>
                    </el-row>
                    <el-row style="margin-bottom: 1vh;"
                            :style="isMobile() ? {height: 'auto'}:{height: '3vw', 'margin-top': '1vh'}">
                        <div
                                :style="isMobile() ? {}:{height: '100%'}"
                                class="course-text" :title="course.title">
                            {{course.title}}
                        </div>
                    </el-row>
                    <el-row :style="isMobile() ? {height: '', 'margin-bottom': '1vh'}:{height: '3vh'}">
                        <el-col :lg="24" :md="24" :xl="24" class="course-detail-row"
                                :title="course.qualification + ' ' + course.duration">
                            {{course.qualification}}<span v-if="course.qualification !== '' && course.duration !== ''"> - </span>
                            <span v-else-if="course.duration !== ''">Duration: </span>{{course.duration}}
                        </el-col>
                    </el-row>
                    <el-row :style="isMobile() ? {height: '', 'margin-bottom': '1vh'}:{height: '3.5vh'}">
                        <el-col class="course-detail-row" :span="12" :title="course.code">Code: {{course.code}}
                        </el-col>
                        <el-col class="course-detail-row" :span="12" :title="course.cao_points"
                                v-if="course.cao_points !== '' && course.cao_points !== 0">
                            CAO Points:
                            <span>{{course.cao_points}}</span>
                        </el-col>
                    </el-row>
                    <el-row class="pointsReqMetBlock">
                        <el-col :span="12" class="pointsReqMet">CAO Points met:
                            <strong>
                                <span v-if="course.cao_points !== 0">{{course.points_met}}</span>
                                <span v-else>NA</span>
                            </strong>
                        </el-col>
                        <el-col :span="12" class="pointsReqMet" style="border-left: 1px solid white;">Requirements met:
                            <strong>
                                {{course.req_met}}
                            </strong>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>


<script>

  import CollegeImage from '../../CommonComponents/CollegeImage.vue'; 

  export default {
    name: 'DisplayList',
    props: {
      displayList: Array,
    },
    components: [ CollegeImage ],
    computed: {},
    methods: {
      courseCardClicked: function(course) {
        if (course.url) {
          let win = window.open(course.url, '_blank');
          if (win) {
            win.focus();
          } else {
            alert('Please allow popups for this website');
          }
        } else {
          this.$router.push('/course/' + course['id'] + '/');
        }
      },
    },
  };
</script>
<style>
    .el-card__body {
        padding: 0 !important;
    }
</style>
<style scoped>

    .course-card-filter >>> .el-card__body {
        padding: 0 !important;
    }

    .row-stack-card {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .header-card {
        position: relative;
        height: 16vh;
        width: 100%;
        padding: 10px;
        margin-top: 1vh;
    }

    .shortlist {
        position: absolute;
        background: #ffffff;
        z-index: 1000;
        right: 16px;
        top: 1%;
        /*border: 1px solid #dedede;*/
        box-sizing: border-box;
        border-radius: 3px;
    }

    .university-row {
        text-align: left;
        padding: 0 16px;
        font-family: Lato, serif;
        font-style: normal;
        font-weight: 300;
        color: #131336;
    }

    .course-card-filter >>> .el-card__header {
        padding: 0 !important;
        border: 0;
    }

    .course-detail-row {
        font-family: Lato, serif;
        font-style: normal;
        font-weight: 300;
        color: #131336;
        text-align: left;
        padding: 0 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .course-text {
        padding: 0 16px;
        font-family: Lato, serif;
        font-style: normal;
        font-weight: 600;
        text-align: left;
        color: #131336;
        width: 100%;
        /*text-overflow: ellipsis;*/
        /*overflow: hidden;*/
        /*white-space: nowrap;*/
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .pointsReqMetBlock {
        color: #FFFFFF;
        height: 4vh;
        background-color: #59A3CC;
        text-align: center;
    }

    .image-course {
        padding: 10px;
        position: relative;
    }

    .course-card-filter:hover {
        cursor: pointer;
    }

</style>
