<template>
    <div class="interest-type">
        <el-row style="margin: 1vh 0 2vh;">
            <div class="interestTypeTitle">{{component.title}}</div>
        </el-row>
        <el-row>
            <el-col :lg="6" :md="6" :sm="24" :xl="6" :xs="24" class="text-content1">
                <p class="text-content1">{{component.p1_1}}</p>
                <p class="text-content1">{{component.p1_2}}</p>
                <p class="text-content1">{{component.p1_3}}</p>
            </el-col>
            <el-col :lg="{span: 7, offset: 1}" :md="{span: 7, offset: 1}" :sm="24" :xl="{span: 7, offset: 1}" :xs="24"
                    class="text-content2">
                <p class="text-content1">{{component.p2_1}}</p>
                <p class="text-content1">{{component.p2_2}}</p>
                <p class="text-content1">{{component.p2_3}}</p>
            </el-col>
            <el-col :span="10" class="image-interest" v-if="!isMobile()">
                <img
                        :src="`${publicPath}img/Personality Overview 1.png`"
                        style="height: 100%; "
                />
            </el-col>
        </el-row>
    </div>
</template>


<script>
  export default {
    name: 'PersonalityTypeContentDesktop',
    data: function() {
      return {
        component: {
          title: 'Career Personality Types',
          p1_1: `The renowned ‘Holland Occupational Themes’ methodology determines your personality type.`,
          p1_2: `John L. Holland developed this methodology over the course of his career, starting way back in the 1950s. `,
          p1_3: `His methods have since gone on to dominate the field of career counselling and have been incorporated into most of the popular assessments used in the field. `,
          p2_1: `Choosing work or an education program that matches, or is similar to your personality, will most likely lead to success and satisfaction.`,
          p2_2: `This scientific methodology groups people on the basis of their suitability for six different categories of occupation – the six Holland Codes!`,
          p2_3: `Click on the tiles below to find out more about each of the codes.`,
        },
      };
    },
  };
</script>

<style scoped>
    .image-interest {
        height: 20vh;
    }

    .title {
        font-family: Niramit, serif;
        font-style: normal;
        font-weight: 300;
        font-size: 3.25vh;
        line-height: 20px;

        /* identical to box height, or 91% */
        display: flex;
        align-items: center;

        color: #000000;
        margin-bottom: 1%;
    }

    .text-content2 {
        /*padding-left: 4vw;*/
    }

    .interest-type {
        margin-bottom: 2%;
    }
</style>