<template>
    <div>
        <el-row style="display: flex; align-items: center; background-color: #17484C; ">
            <el-col :xl="8" :lg="8" :md="8" :sm="24" :xs="24" @click.native="$router.go(-1)" class="component-title"
                    style="cursor: pointer;">
                <back-arrow></back-arrow>
                {{getPageTitle}}
            </el-col>
            <el-col :offset="14" :span="2" v-if="!isMobile()">
                <profile-avatar></profile-avatar>
            </el-col>
        </el-row>
        <el-row :style="isMobile()?{height: '75vh'}:{height: 'calc(100vh - (32px + 20px + 3vh + 1.5vw))'}"
                class="course-search-page"
                style="overflow-y: auto; overflow-x: hidden">
            <CourseCollegeDesktopTop :courseDetails="courses"/>
            <el-row style="padding: 5px;">
                <el-col
                        :lg="{span: 11, offset: 1}"
                        :md="{span: 11, offset: 1}" :sm="24"
                        :xl="{span: 11, offset: 1}" :xs="24"
                        v-if="isMobile()">
                    <SubjectCompatibility :courseDetails="getCourseData.subjects"
                                          :courseFitPercent="getCourseFit"
                                          v-if="getCourseData.subjects.status === 2 && getIsPremiumUser"/>
                    <CareerFit
                            :courseDetails="{'careers': getCourseData.careers_sector_keywords, 'percent': getCareer}"
                            :status="getCourseData.subjects.status"
                            @showPaymentPopover="paymentDialog = true"/>
                    <PersonalityFit
                            :courseDetails="{'personalities': getCourseData.career_interests_keywords, 'initials':getPersonality}"
                            :status="getCourseData.subjects.status"
                            @showPaymentPopover="paymentDialog = true"/>
                </el-col>
                <el-col :lg="{span: 14}" :md="{span: 14}" :sm="24" :xl="{span: 14}"
                        :xs="24" :style="!isMobile() ? {padding: '16px'}:{margin: '1vw'}">
                    <el-row v-if="getYoutubeVideoId !== '' && getYoutubeVideoId !== 'https://www.youtube.com/embed/' && getYoutubeVideoId !== 'http://www.youtube.com/embed/'">
                        <el-col :lg="{span: 20, offset:2}" :md="{span: 20, offset:2}" :sm="{span: 24}"
                                :xl="{span: 20, offset:2}" :xs="{span: 24}">
                            <iframe :src="getYoutubeVideoId" allowfullscreen frameborder="0" height="250"
                                    mozallowfullscreen webkitallowfullscreen width="100%"></iframe>

                        </el-col>
                    </el-row>
                    <CourseInformation :courseDetails="getCourseData"/>
                </el-col>
                <el-col v-if="isMobile()">
                    <Accommodation v-if="getCollegeLocation[0] !== 0 && getCollegeLocation[1] !== 0"
                                   :latitude="getCollegeLocation[0]" :longitude="getCollegeLocation[1]"/>
                </el-col>
                <el-col
                        :lg="{span: 9, offset: 1}"
                        :md="{span: 9, offset: 1}"
                        :sm="24" :xl="{span: 9, offset: 1}"
                        :xs="24" style="padding: 16px"
                        v-if="!isMobile()">
                    <SubjectCompatibility :courseDetails="getCourseData.subjects"
                                          :courseFitPercent="getCourseFit"
                                          v-if="getCourseData.subjects.status === 2 && getIsPremiumUser"/>
                    <CareerFit
                            :courseDetails="{'careers': getCourseData.careers_sector_keywords, 'percent': getCareer}"
                            :status="getTestStatus"
                            @showPaymentPopover="paymentDialog = true"/>
                    <PersonalityFit
                            :courseDetails="{'personalities': getCourseData.career_interests_keywords, 'initials': getPersonality}"
                            :status="getTestStatus"
                            @showPaymentPopover="paymentDialog = true"/>
                    <Accommodation v-if="getCollegeLocation[0] !== 0 && getCollegeLocation[1] !== 0"
                                   :collegeName="getCourseData.college_name"
                                   :latitude="getCollegeLocation[0]" :longitude="getCollegeLocation[1]"/>
                </el-col>
            </el-row>
            <payment-popover :status="paymentDialog" @close="closePaymentDialog" v-if="paymentDialog"></payment-popover>

        </el-row>
    </div>
</template>

<script>
  import CourseCollegeDesktopTop from '../../page_components/CourseCollegeComponents/web/course_college_top.vue';
  import SubjectCompatibility from '../../page_components/CourseCollegeComponents/web/subject_compatibility.vue';
  import CareerFit from '../../page_components/CourseCollegeComponents/web/career_fit.vue';
  import PersonalityFit from '../../page_components/CourseCollegeComponents/web/personality_fit.vue';
  import Accommodation from '../../page_components/CourseCollegeComponents/web/accommodation.vue';
  import CourseInformation from '../../page_components/CourseCollegeComponents/web/course_info.vue';
  //changes for Mobile
  import ProfileAvatar from '../../main_components/ProfileAvatar';
  import PaymentPopover from '../../main_components/PaymentPopover';
  import BackArrow from '../../main_components/BackArrow';

  export default {
    name: 'Course',
    props: ['courseId'],
    components: {
      BackArrow,
      PaymentPopover,
      ProfileAvatar,
      CourseCollegeDesktopTop,
      SubjectCompatibility,
      CareerFit,
      PersonalityFit,
      Accommodation,
      CourseInformation,
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        if (from.path === '/skillsvista_fit/')
          vm.pageTitle = 'Skillsvista Fit';
        else
          vm.pageTitle = 'Course Search';
      });
    },
    data: function() {
      return {
        paymentDialog: false,
        pageTitle: '',
        courseData: {title: '', subjects: {status: 0}},
        currentCourseId: false,
        loadingScreen: false,
        startTime: false,
        courses: {},
        videoId: false,
        collegeLocation: [],
      };
    },
    computed: {
      getPageTitle: function() {
        return this.pageTitle;
      },
    },
    mounted() {
      let self = this;
      self.$store.state.loadingScreen = true;
      self.checkUserLoggedIn().then(response => {
        if (response.user_type === 2) {
          self.$router.push('/counsellor/home/');
        }
        self.currentCourseId = self.courseId;
        if (self.currentCourseId !== undefined) {
          self.$cookies.set('latestCourseId', self.currentCourseId);
        } else {
          self.currentCourseId = self.$cookies.get('latestCourseId');
        }
        self.$store.state.loadingScreen = true;
        self.getCourseDetails(self.currentCourseId).then(response => {
          self.setSelectedCourseData(response);
          self.$store.state.loadingScreen = false;
        }).catch(error => {
          error;
          self.$store.state.loadingScreen = false;
        });
      }).catch(error => {
        error;
        self.$store.state.loadingScreen = false;
      });
    },

    methods: {
      closePaymentDialog: function() {
        this.paymentDialog = false;
      },
      backToCourseSearch: function() {
        this.$router.push('/course_filter/');
      },
    },
  };
</script>
<style>
</style>
<style scoped>
    .el-card__body {
        padding: 0 !important;
    }

    iframe {
        width: 100% !important;
    }

    .course-search-page {
        /*background: #f5f6fa;*/
    }
</style>
